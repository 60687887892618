import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, combineLatest, of } from 'rxjs';
import {
	catchError,
	filter,
	finalize,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import { getEnvironment } from 'src/lib/environment/environment';
import { UserAgreementModel } from 'src/lib/services/api/users/agreements/user-agreement.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { PermissionFieldSet } from 'src/lib/services/stores/permission-store/permission-field-set';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { ConfirmPopupService } from 'src/lib/services/utility/confirm-popup/confirm-popup.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { mergeStrings } from 'src/lib/utilities/array';
import { hasValue } from 'src/lib/utilities/compare';
import { convertToInt } from 'src/lib/utilities/convert';
import { commonValidators } from 'src/lib/utilities/forms';
import { PasswordRandomizerComponent } from '../../../../templates/controls/password-randomizer/password-randomizer.component';
import { AutofocusDirective } from '../../../../templates/global/autofocus/autofocus.directive';
import { InputTrimDirective } from '../../../../templates/global/email-trim/input-trim.directive';
import { GroupValidationDisplayComponent } from '../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../templates/layout/group-validation/validation-error.directive';
import { SpinWhileDirective } from '../../../../templates/layout/spin-while/spin-while.directive';
import { PrintModalService } from '../../../modals/print-modal/print-modal.service';
import { ProfileListenerService } from '../profile-listener.service';

interface PasswordFormGroup {
	newPassword: string;
	oldPassword: string;
	confirmPassword: string;
}

interface EmailFormGroup {
	email: string;
	oldPassword: string;
}

@Component({
	selector: 'ae-profile-security',
	templateUrl: './profile-security.component.html',
	styleUrls: ['./profile-security.component.scss'],
	standalone: true,
	imports: [
		AutofocusDirective,
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		InputTrimDirective,
		PasswordRandomizerComponent,
		ReactiveFormsModule,
		SpinWhileDirective,
		ValidationErrorDirective,
	],
})
export class ProfileSecurityComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public title = 'Account Info';

	private currentUserId: number;

	public loading: boolean = true;
	public errorLoading: boolean = false;
	public ssoErrorLoading: boolean = false;
	public processing: boolean = false;

	public isEditingEmail: boolean = false;
	public accountEmail: string;
	public ssoEmails: string[];

	public isEditingPassword: boolean = false;

	public passwordForm: FormGroup<FormControlWrapper<PasswordFormGroup>>;
	public emailForm: FormGroup<FormControlWrapper<EmailFormGroup>>;

	public SSOEnabled: boolean = false;
	public SSOLoading: boolean = true;
	public SSOLink: string;
	public connectionType: string;
	public canSSO: boolean = false;
	public status: boolean = true;
	public canDeactivateAccount: boolean = false;
	public canReactivateAccount: boolean = false;

	get isOwnProfile(): boolean {
		return this.currentUserId === this.userStore.currentUserUid;
	}

	private permissions: PermissionFieldSet;

	public loadingImageUrl = `${getEnvironment().assets}/images/g-logo.png`;

	constructor(
		private fb: FormBuilder,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private userService: UsersService,
		private userStore: UserStoreService,
		private confirmPopupService: ConfirmPopupService,
		private permissionService: PermissionStoreService,
		private profileListenerService: ProfileListenerService,
		private printModalService: PrintModalService,
	) {}

	ngOnInit() {
		this.passwordForm = this.fb.group<FormControlWrapper<PasswordFormGroup>>(
			{
				newPassword: new FormControl(null, [
					commonValidators.password,
					commonValidators.requiredNotEmpty,
					commonValidators.passwordForbiddenValues,
				]),
				confirmPassword: new FormControl(null, [
					commonValidators.requiredNotEmpty,
				]),
				oldPassword: new FormControl(null, [commonValidators.requiredNotEmpty]),
			},
			{ validators: [this.confirmedValidator, this.sameCurrentValidator] },
		);

		this.emailForm = this.fb.group<FormControlWrapper<EmailFormGroup>>({
			email: new FormControl(null, [
				Validators.required,
				commonValidators.emailAndLength,
			]),
			oldPassword: new FormControl(null, [commonValidators.requiredNotEmpty]),
		});

		this.emailForm.disable();

		combineLatest([this.permissionService.getFieldSet$(), this.route.params])
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(([perms, params]) => {
				this.permissions = perms;
				this.currentUserId = convertToInt(params.userId);
				this.getStudentInfo();
				this.tryRefreshSSO();
			});
	}

	confirmedValidator: ValidatorFn = (
		group: FormGroup,
	): ValidationErrors | null => {
		const values = group.value;
		return !group.controls.confirmPassword.dirty ||
			values.confirmPassword === values.newPassword
			? null
			: { passwordMismatch: 'New and Confirm password must match' };
	};

	sameCurrentValidator: ValidatorFn = (
		group: FormGroup,
	): ValidationErrors | null => {
		const values = group.value;

		if (
			hasValue(values.oldPassword) &&
			values.oldPassword === values.newPassword
		) {
			return { sameAsCurrent: 'You cannot reuse the same password' };
		} else return null;
	};

	public getStudentInfo = () => {
		this.loading = true;
		this.errorLoading = false;

		this.userService
			.getUserData(this.currentUserId)
			.pipe(finalize(() => (this.loading = false)))
			.subscribe({
				next: (overview) => {
					this.accountEmail = overview?.contact_field?.email?.find((e) => {
						return e.prim === true;
					})?.value;
					if (!this.accountEmail) {
						this.accountEmail = overview?.contact_field?.email[0]?.value ?? '-';
					}

					this.ssoEmails =
						overview?.contact_field?.email
							.filter((x) => x.active && !x.prim)
							.map((x) => x.value) ?? [];

					this.status = overview.user.status;
					this.errorLoading = false;
				},
				error: (errors) => {
					console.error(mergeStrings(errors));
					this.errorLoading = true;
				},
			});
	};

	public updateEmail = () => {
		this.passwordForm.disable();
		this.emailForm.disable();
		this.processing = true;

		let newEmail = null;
		if (this.emailForm.controls.email && this.emailForm.controls.email.value) {
			newEmail = this.emailForm.controls.email.value;
		}

		this.userService
			.updateUserEmailPassword(
				this.currentUserId,
				null,
				newEmail,
				this.emailForm.controls.oldPassword.value,
			)
			.pipe(
				tap((response) => {
					this.passwordForm.enable();
					this.emailForm.enable();
					if (response.success) {
						this.toastr.success(`Email updated`, this.title);
						this.accountEmail = newEmail;
						this.isEditingEmail = false;
						this.emailForm.reset();
					} else {
						this.toastr.error('Current Password is invalid');
						this.emailForm.controls.oldPassword.setErrors({
							passwordNotValid: true,
						});
					}
				}),
				catchError((err) => {
					this.toastr.error(
						'There was an issue updating the account info, if the problem persists contact support',
						this.title,
					);
					this.passwordForm.enable();
					this.emailForm.enable();
					return of(err);
				}),
			)
			.subscribe(() => {
				this.processing = false;
			});
	};

	public updatePassword = () => {
		this.processing = true;

		this.userService
			.updateUserEmailPassword(
				this.currentUserId,
				this.passwordForm.controls.confirmPassword.value,
				null,
				this.passwordForm.controls.oldPassword.value,
			)
			.pipe(
				tap((response: any) => {
					this.passwordForm.enable();
					this.emailForm.enable();
					if (response.success) {
						this.toastr.success(`Password updated`, this.title);
						this.isEditingPassword = false;
						this.passwordForm.reset();
					} else {
						this.toastr.error('Current Password is invalid');
						this.passwordForm.controls.oldPassword.setErrors({
							passwordNotValid: true,
						});
					}
				}),
				catchError((err) => {
					this.toastr.error(
						'There was an issue updating the account info, if the problem persists contact support',
						this.title,
					);
					this.passwordForm.enable();
					this.emailForm.enable();
					return of(err);
				}),
			)
			.subscribe(() => {
				this.processing = false;
			});
	};

	public togglePasswordEdit = () => {
		this.isEditingPassword = !this.isEditingPassword;
		if (this.isEditingPassword) {
			this.passwordForm.controls.newPassword.clearValidators();
			this.passwordForm.controls.newPassword.setValidators([
				commonValidators.password,
				commonValidators.passwordForbiddenValues,
			]);
		} else {
			this.passwordForm.controls.newPassword.clearValidators();
			this.passwordForm.controls.newPassword.setValidators([
				commonValidators.password,
				Validators.required,
				commonValidators.passwordForbiddenValues,
			]);
		}
		this.passwordForm.controls.newPassword.updateValueAndValidity();
	};

	public toggleEmailEdit = () => {
		this.isEditingEmail = !this.isEditingEmail;

		if (this.isEditingEmail) {
			this.emailForm.enable();
			this.emailForm.controls.email.setValue(this.accountEmail);
		} else {
			this.emailForm.controls.email.setValue(null);
			this.emailForm.disable();
		}
		this.emailForm.updateValueAndValidity();
	};

	public tryRefreshSSO = () => {
		this.ssoErrorLoading = false;

		if (this.isOwnProfile) {
			this.canSSO = this.permissions.canDo(
				Permits['manage own openid connect accounts'],
			);
		} else {
			this.canSSO = this.permissions.canDo(
				Permits['administer openid connect clients'],
			);

			this.canDeactivateAccount = this.permissions.canDo(
				Permits['ga_user|disable any user account'],
			);

			this.canReactivateAccount = this.permissions.canDo(
				Permits['ga_user|enable any user account'],
			);
		}

		if (this.canSSO) {
			this.refreshSSO();
		} else {
			this.SSOLoading = false;
		}
	};

	private refreshSSO = () => {
		this.SSOLoading = true;
		this.SSOEnabled = false;
		this.userService.getOpenIdStatus(this.currentUserId).subscribe({
			next: (response) => {
				if (response.connect == null && response.connection == null) {
					this.canSSO = false;
				} else if (response.connect) {
					this.SSOLink = response.connect.google;
				} else if (response.connection) {
					this.connectionType = response.connection;
					this.SSOEnabled = true;
				} else {
					this.ssoErrorLoading = true;
				}
				this.SSOLoading = false;
			},
			error: (errors) => {
				console.error(mergeStrings(errors));
				this.ssoErrorLoading = true;
			},
		});
	};

	public removeSSO = () => {
		this.confirmPopupService
			.confirm$({
				message: `Are you sure you want to disconnect Single Sign On?`,
			})
			.pipe(
				filter((x) => x),
				switchMap(() => {
					this.SSOLoading = true;
					return this.userService.disconnectOpenIdStatus(
						this.currentUserId,
						this.connectionType,
					);
				}),
			)
			.subscribe({
				next: (response) => {
					if (response.success) {
						this.refreshSSO();
					}
				},
				error: (errors) => {
					this.toastr.error(mergeStrings(errors));
					this.SSOLoading = false;
				},
			});
	};

	public deactivateAccount() {
		this.confirmPopupService
			.confirm$({
				message: 'Are you sure you want to deactivate this user?',
			})
			.pipe(
				filter((x) => x),
				switchMap(() => this.userService.deactivateUser(this.currentUserId)),
			)
			.subscribe({
				next: () => {
					this.getStudentInfo();
					this.profileListenerService.sendSignal('user.name');
				},
				error: (err) => {
					console.error('Error deactivating account', err);
				},
			});
	}

	public reactivateAccount() {
		this.confirmPopupService
			.confirm$({
				message: 'Are you sure you want to activate this user?',
			})
			.pipe(
				filter((x) => x),
				switchMap(() => this.userService.reactivateUser(this.currentUserId)),
			)
			.subscribe({
				next: () => {
					this.getStudentInfo();
					this.profileListenerService.sendSignal('user.name');
				},
				error: (err) => {
					console.error('Error reactivating account', err);
				},
			});
	}

	public openAgreementModal = (agreement: UserAgreementModel) => {
		this.printModalService
			.openModal$(agreement.body, agreement.title)
			.subscribe();
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
