import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class TranscriptTrackerResponseModel {
	success: boolean;
	count: number;
	@Type(TranscriptTrackerItemModel_ClassTransform_Type)
	transcripts?: TranscriptTrackerItemModel[];
}

export class TranscriptTrackerItemModel {
	get profile_first_name() {
		return this.student_first_name;
	}
	get profile_preferred_name() {
		return this.student_preferred_name;
	}
	get profile_last_name() {
		return this.student_last_name;
	}
	get uid() {
		return this.student_uid;
	}

	assigned_ac: string;
	credit_check: boolean;
	credits_remain: number;
	id: string;
	last_parent_program_name: string;
	last_program_name: string;
	last_program_state: string;
	last_program_type: string;
	most_recently_assigned_ec: string;
	note: string;
	@Transform(dateTransformer)
	pending_verification_date: Date;
	@Transform(dateTransformer)
	student_enrolled_date: Date;
	student_is_referral: boolean;
	student_name: string;
	student_first_name: string;
	student_last_name: string;
	student_preferred_name: string;
	@Transform(dateTransformer)
	student_registered_date: Date;
	student_uid: number;
	@Transform(dateTransformer)
	transcript_entered: Date;
	@Transform(dateTransformer)
	transcript_mapped: Date;
	@Transform(dateTransformer)
	transcript_locked: Date;
	@Transform(dateTransformer)
	transcript_uploaded: Date;
	@Transform(dateTransformer)
	verification_complete_date: Date;
	notes: string;

	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
}

export function TranscriptTrackerModel_ClassTransform_Type() {
	return TranscriptTrackerItemModel;
}

export function TranscriptTrackerItemModel_ClassTransform_Type() {
	return TranscriptTrackerItemModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface TranscriptTrackerPagingArgument
	extends PagingArgument<TranscriptTrackerSearchArgument> {}

export interface TranscriptTrackerSearchArgument {
	student_is_referral: PagingSearchFieldArgument;
	credit_check: PagingSearchFieldArgument;
	state: PagingSearchFieldArgument;
	assigned_ac: PagingSearchFieldArgument;
	most_recently_assigned_ec: PagingSearchFieldArgument;
	last_program_name: PagingSearchFieldArgument;
	last_program_state: PagingSearchFieldArrayArgument;
	last_program_type: PagingSearchFieldArrayArgument;
	work_queue: PagingSearchFieldArgument;
	student_registered_date: PagingSearchFieldArgument;
	student_enrolled_date: PagingSearchFieldArgument;
	transcript_uploaded: PagingSearchFieldArgument;
	transcript_entered: PagingSearchFieldArgument;
	transcript_mapped: PagingSearchFieldArgument;
	transcript_locked: PagingSearchFieldArgument;
	pending_verification_date: PagingSearchFieldArgument;
	verification_complete_date: PagingSearchFieldArgument;
	include_deactive?: boolean;
	student_first_name: PagingSearchFieldArgument;
	student_last_name: PagingSearchFieldArgument;
	credits_remain: PagingSearchFieldArgument;
	student_uid: PagingSearchFieldArgument;
}
