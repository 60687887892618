import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import spacetime from 'spacetime';
import { UserUpdateRequestArgument } from 'src/lib/services/api/users/update-request/users-update-request.argument';
import { UserUpdateRequestOptions } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import {
	UserDataProfileModel,
	UserDataStudentModel,
} from 'src/lib/services/api/users/user-data.model';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { StringEnumOption } from 'src/lib/utilities/api/patterns/dynamic-fields/string-enum-option.model';
import {
	hasKey,
	hasSomeKey,
	hasValue,
	isEquivalentString,
	isNullOrEmptyString,
} from 'src/lib/utilities/compare';
import { commonValidators } from 'src/lib/utilities/forms';
import { SingleDatePickerComponent } from '../../../../../templates/controls/single-date-picker/single-date-picker.component';
import { InputTrimDirective } from '../../../../../templates/global/email-trim/input-trim.directive';
import { IconTooltipComponent } from '../../../../../templates/global/icon-tooltip/icon-tooltip.component';
import { GroupValidationDisplayComponent } from '../../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../../templates/layout/group-validation/validation-error.directive';
import { ProfileListenerService } from '../../profile-listener.service';

export interface ProfileInfoFormGroup {
	first_name: string;
	middle_name: string;
	last_name: string;
	maiden_name: string;
	suffix: string;
	preferred_name: string;
	birthdate: Date;
	gender: string;
	race: string;
	about_me: string;
	city: string;
	state: string;
	intro_video: string;
}

@Component({
	selector: 'ae-profile-info-edit',
	templateUrl: './profile-info-edit.component.html',
	styleUrls: ['./profile-info-edit.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		IconTooltipComponent,
		InputTrimDirective,
		NgSelectModule,
		ReactiveFormsModule,
		SingleDatePickerComponent,
		ValidationErrorDirective,
	],
})
export class ProfileInfoEditComponent implements OnInit {
	@Input() public profileData: UserDataProfileModel;
	@Input() public studentData: UserDataStudentModel;
	@Input() public loading: boolean;
	@Input() public enableEdit: () => void;
	@Input() public existingPendingChange: boolean;
	@Input() public accountOptions: UserUpdateRequestOptions;
	@Input() public currentUserId: number;
	@Input() public resetProfileInfoForm: (forceRefresh: boolean) => void;

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;

	public savingInfo: boolean = false;

	public profileInfoFormGroup: FormGroup<
		FormControlWrapper<ProfileInfoFormGroup>
	>;

	public dropdownGender: StringEnumOption[];
	public dropdownRace: StringEnumOption[];
	public dropdownState: StringEnumOption[];

	public currentDate = new Date();

	public birthdateAllowed: boolean = false;
	public hasChanges: boolean = false;

	public hasKey = hasKey;
	public hasSomeKey = hasSomeKey;

	private youtubeRegex =
		/(https:\/\/)(www\.)?youtu(((?:.*\/v\/|.*v=|\.be\/))|(be\.com\/shorts\/))([A-Za-z0-9_-]{11})/;

	constructor(
		private fb: FormBuilder,
		private userRequestService: UsersUpdateRequestsService,
		private toastr: ToastrService,
		private cdr: ChangeDetectorRef,
		private profileListenerService: ProfileListenerService,
	) {}

	ngOnInit() {
		this.profileInfoFormGroup = this.fb.group<
			FormControlWrapper<ProfileInfoFormGroup>
		>({
			// Profile
			first_name: new FormControl(this.profileData?.profile_first_name, [
				Validators.required,
				commonValidators.requiredNotEmpty,
			]),
			last_name: new FormControl(this.profileData?.profile_last_name, [
				Validators.required,
				commonValidators.requiredNotEmpty,
			]),
			middle_name: new FormControl(this.profileData?.profile_middle_name),
			maiden_name: new FormControl(this.profileData?.profile_maiden_name),
			suffix: new FormControl(this.profileData?.profile_suffix),
			preferred_name: new FormControl(this.profileData?.profile_preferred_name),
			birthdate: new FormControl(this.studentData?.dob, [
				(ctrl) => {
					if (
						hasValue(ctrl.value) &&
						hasValue(this.accountOptions.student_data?.dob?.minimum_age)
					) {
						const minimumDate = spacetime()
							.subtract(
								this.accountOptions.student_data.dob.minimum_age,
								'year',
							)
							.startOf('day');
						if (minimumDate.isBefore(ctrl.value)) {
							return {
								minimum_age: `Must be at least ${this.accountOptions.student_data.dob.minimum_age} years old`,
							};
						}
					}

					return null;
				},
			]),
			race: new FormControl(this.profileData?.profile_student_race),
			gender: new FormControl(this.profileData?.profile_student_gender),

			// Social
			about_me: new FormControl(this.profileData?.profile_about),
			state: new FormControl(this.profileData?.profile_state),
			city: new FormControl(this.profileData?.profile_city),
			intro_video: new FormControl(
				this.profileData?.profile_intro_video
					? `https://www.youtube.com/watch?v=${this.profileData?.profile_intro_video}`
					: '',
				[
					(ctrl) => {
						if (hasValue(ctrl.value)) {
							if (!this.youtubeRegex.test(ctrl.value.trim())) {
								return {
									valid_link: `Must be a valid youtube link`,
								};
							}
						}

						return null;
					},
				],
			),
		});

		this.cdr.detectChanges();

		// Permissions
		if (hasKey(this.studentData, 'dob')) {
			this.birthdateAllowed = true;
		} else {
			this.profileInfoFormGroup.controls.birthdate.disable();
		}

		if (!hasKey(this.profileData, 'profile_about')) {
			this.profileInfoFormGroup.controls.about_me.disable();
		}

		if (!hasKey(this.profileData, 'profile_state')) {
			this.profileInfoFormGroup.controls.state.disable();
		}

		if (!hasKey(this.profileData, 'profile_city')) {
			this.profileInfoFormGroup.controls.city.disable();
		}

		if (!hasKey(this.profileData, 'profile_intro_video')) {
			this.profileInfoFormGroup.controls.intro_video.disable();
		}

		this.dropdownGender =
			this.accountOptions?.profile?.profile_student_gender?.options;
		this.dropdownRace =
			this.accountOptions?.profile?.profile_student_race?.options;
		this.dropdownState =
			this.accountOptions?.contact_field?.address?.subfields?.address?.subfields?.state?.options;

		const originalForm = this.profileInfoFormGroup.value;

		this.profileInfoFormGroup.valueChanges
			.pipe(debounceTime(1000))
			.subscribe(() => {
				const controlGroup = this.profileInfoFormGroup.controls;
				this.hasChanges = Object.keys(controlGroup).some((key) => {
					if (key === 'intro_video') {
						//perform regex check
						const formURLMatch =
							this.profileInfoFormGroup.controls.intro_video.value.match(
								this.youtubeRegex,
							);
						const introURLMatch = this.profileData.profile_intro_video
							? `https://www.youtube.com/watch?v=${this.profileData.profile_intro_video}`.match(
									this.youtubeRegex,
								)
							: [];
						if (formURLMatch) {
							const len = formURLMatch.length;
							const introVideoLen = introURLMatch.length;
							const intro_video = formURLMatch[len - 1];
							const original_intro_video = introVideoLen
								? introURLMatch[introVideoLen - 1]
								: null;
							return original_intro_video !== intro_video;
						} else {
							return (
								isNullOrEmptyString(
									this.profileInfoFormGroup.controls.intro_video.value,
								) && !isNullOrEmptyString(this.profileData.profile_intro_video)
							);
						}
					} else {
						if (
							this.profileInfoFormGroup.value[key]?.trim != null &&
							originalForm[key]?.trim != null
						) {
							return (
								this.profileInfoFormGroup.value[key]?.trim() !==
								originalForm[key]?.trim()
							);
						} else if (this.profileInfoFormGroup.value[key] instanceof Date) {
							return !spacetime(this.profileInfoFormGroup.value[key])
								.startOf('day')
								.isEqual(
									spacetime(originalForm[key]).startOf('day').toNativeDate(),
								);
						} else {
							return this.profileInfoFormGroup.value[key] !== originalForm[key];
						}
					}
				});
			});
	}

	public save = () => {
		this.savingInfo = true;
		const controlGroup = this.profileInfoFormGroup.controls;
		let intro_video = null;

		if (controlGroup.intro_video.value) {
			const URLMatch = controlGroup.intro_video.value.match(this.youtubeRegex);
			const len = URLMatch.length;
			intro_video = URLMatch[len - 1];
		}

		const profileContactArgs: UserUpdateRequestArgument = {
			profile: {
				profile_first_name: controlGroup.first_name.value,
				profile_middle_name: controlGroup.middle_name.value,
				profile_suffix: controlGroup.suffix.value,
				profile_last_name: controlGroup.last_name.value,
				profile_maiden_name: controlGroup.maiden_name.value,
				profile_preferred_name: controlGroup.preferred_name.value,
				profile_about: controlGroup.about_me.value,
				profile_city: controlGroup.city.value,
				profile_state: controlGroup.state.value,
				profile_student_ethnicity: controlGroup.race.value,
				profile_student_gender: controlGroup.gender.value,
				profile_intro_video: intro_video,
			},
		};

		Object.keys(profileContactArgs.profile).forEach((key) => {
			let testKey: string = key;

			// API still refers to race as ethnicity 14E31FFD
			if (key === 'profile_student_ethnicity') {
				testKey = 'profile_student_race';
			}

			if (!this.hasKey(this.profileData, testKey as any)) {
				delete profileContactArgs.profile[key];
			}
		});

		if (
			!isNullOrEmptyString(profileContactArgs.profile.profile_preferred_name) &&
			isEquivalentString(
				profileContactArgs.profile.profile_preferred_name,
				profileContactArgs.profile.profile_first_name,
			)
		) {
			profileContactArgs.profile['profile_preferred_name'] = null;
		}

		if (
			this.birthdateAllowed &&
			this.profileInfoFormGroup.controls.birthdate.enabled &&
			this.studentData?.dob !== controlGroup.birthdate.value
		) {
			profileContactArgs.student_data = {
				dob: controlGroup.birthdate.value,
			};
		}

		if (
			!isNullOrEmptyString(profileContactArgs.profile.profile_preferred_name) &&
			isEquivalentString(
				profileContactArgs.profile.profile_preferred_name,
				profileContactArgs.profile.profile_first_name,
			)
		) {
			profileContactArgs.profile['profile_preferred_name'] = null;
		}

		this.profileInfoFormGroup.disable();

		this.userRequestService
			.createUpdateRequest(this.currentUserId, profileContactArgs)
			.subscribe({
				next: (response) => {
					this.savingInfo = false;
					this.profileInfoFormGroup.enable();
					if (response.success) {
						if (response.update_request) {
							this.toastr.success(
								'Profile info update has been requested. Your changes will not be shown until they are approved.',
							);
						} else {
							this.toastr.success('Profile info has been updated.');
							this.profileListenerService.sendSignal('user.name');
						}

						this.resetProfileInfoForm(true);
					} else {
						this.toastr.error(
							`There was an error when updating profile info ${(
								response as any
							)?.error_messages.join('\n')}`,
						);
					}
				},
				error: () => {
					this.savingInfo = false;
					this.profileInfoFormGroup.enable();

					this.toastr.error(`There was an error when updating profile info`);
				},
			});
	};
}
