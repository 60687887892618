/* eslint-disable @angular-eslint/component-selector */

import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AsyncSubject, catchError, finalize, takeUntil } from 'rxjs';
import { getEnvironment } from 'src/lib/environment/environment';
import {
	LoginProgramModel,
	LoginService,
	ProfileAccessibilityStatementModalService,
} from 'src/public.services';
import { SpinWhileDirective } from 'src/public.templates';
import {
	buildPageTitle,
	buildSearchField,
	hasValue,
	isNonEmptyString,
	SearchOperators,
} from 'src/public.utilities';
import { CredentialLoginComponent } from './components/credential-login/credential-login.component';
import { NeedHelpComponent } from './components/need-help/need-help.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { SsoButtonComponent } from './components/sso-button/sso-button.component';
import { SsoLoginComponent } from './components/sso-login/sso-login.component';
import { SsoProgramSelectComponent } from './components/sso-program-select/sso-program-select.component';
import { LoginState } from './login-state.enum';
import { LoginSaveService } from './services/login-save.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [
		CredentialLoginComponent,
		NeedHelpComponent,
		NgClass,
		PasswordResetComponent,
		SpinWhileDirective,
		SsoButtonComponent,
		SsoLoginComponent,
		SsoProgramSelectComponent,
	],
	providers: [],
})
export class AppComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public currentState: LoginState;
	public loginState = LoginState;

	public currentProgram: LoginProgramModel = null;
	public currentEmail: string = null;
	public url: URL = null;

	public loginLogo = `${getEnvironment().assets}/images/login-logo.png`;

	public loading = true;
	public processing = false;

	constructor(
		private title: Title,
		private profileAccessibilityStatementModalService: ProfileAccessibilityStatementModalService,
		private loginService: LoginService,
		private loginSaveService: LoginSaveService,
	) {}

	ngOnInit(): void {
		this.title.setTitle(buildPageTitle());
		this.appInit();
		this.checkParams();
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	private appInit = () => {
		document.documentElement.style.setProperty(
			'--login-bg-img',
			`url('${getEnvironment().assets}/images/GA-login-image${Math.floor(Math.random() * 4 + 1)}.jpg')`,
		);

		// Disable browser default handling of drag/drop so we can allow drag/drop on messages
		const preventDefault = (e: Event) => {
			e?.preventDefault();
		};

		globalThis.addEventListener('dragover', preventDefault, false);
		globalThis.addEventListener('drop', preventDefault, false);
	};

	private checkParams = (): void => {
		let uniqueId = null;
		this.url = new URL(document.location.href);
		const storedUniqueId = this.loginSaveService.getUniqueId();

		if (this.url.href.includes('/p/')) {
			uniqueId = this.url.href.split('/p/')[1];
		} else if (isNonEmptyString(storedUniqueId)) {
			uniqueId = storedUniqueId;
		}

		if (hasValue(uniqueId)) {
			this.loginService
				.getPrograms({
					institute_title: null,
					institute_unique_id: buildSearchField(
						uniqueId,
						SearchOperators.Equals,
					),
				})
				.pipe(
					catchError(() => {
						console.error('Error loading programs');
						return [];
					}),
					finalize(() => {
						this.loading = false;
					}),
					takeUntil(this._unsubscribe$),
				)
				.subscribe((programs) => {
					if (programs.length === 1) {
						this.setSelectedProgram(programs[0]);
					} else {
						if (uniqueId === storedUniqueId) {
							this.loginSaveService.setUniqueId('');
						}
						this.currentState = LoginState.credential;
					}
				});
		} else {
			this.currentState = LoginState.credential;
			this.loading = false;
		}
	};

	public setState = (state: LoginState): void => {
		this.currentState = state;
	};

	public setSelectedProgram = (program: LoginProgramModel): void => {
		this.currentProgram = program;

		this.setState(LoginState.sso);
	};

	public setProcessing = (processing: boolean): void => {
		this.processing = processing;
	};

	public setEmail = (email: string): void => {
		this.currentEmail = email;
	};

	public openAccessibilityStatement = (): void => {
		this.profileAccessibilityStatementModalService
			.openModal$()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe();
	};
}
