// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvironmentTemplate } from '../../../../src/public.environment';

export const environment = new EnvironmentTemplate({
	production: false,
	assetRoot: '/aeclient/dist/aeweb-login/browser/assets',
});

import '../../../../src/lib/environment/common-dev-environment';
