import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject } from 'rxjs';
import { ExternalAccountModel } from 'src/lib/services/api/external-accounts/external-account.model';
import { ExternalAccountsService } from 'src/lib/services/api/external-accounts/external-accounts.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { MapResponseErrors } from 'src/lib/utilities/api/map-response';
import { mergeStrings } from 'src/lib/utilities/array';
import { ModalMoverComponent } from '../../../templates/global/modal-mover/modal-mover.component';
import { GroupValidationDisplayComponent } from '../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../templates/layout/group-validation/validation-error.directive';
import { SpinWhileDirective } from '../../../templates/layout/spin-while/spin-while.directive';

interface ExternalAccountFormGroup {
	account: ExternalAccountModel;
}

@Component({
	selector: 'ae-external-account-creation-modal',
	templateUrl: './external-account-creation-modal.component.html',
	styleUrls: ['./external-account-creation-modal.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		ModalMoverComponent,
		NgSelectModule,
		ReactiveFormsModule,
		SpinWhileDirective,
		ValidationErrorDirective,
	],
})
export class ExternalAccountCreationModalComponent implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public externalAccountFG: FormGroup<
		FormControlWrapper<ExternalAccountFormGroup>
	>;

	public options: ExternalAccountModel[];
	public title = 'Add External Account';
	public loading = true;
	public saving = false;
	private userId: number;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private fb: FormBuilder,
		private toastr: ToastrService,
		private externalAccountsService: ExternalAccountsService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { userId: number }): void => {
		// SET DATA
		this.userId = data.userId;
		this.externalAccountFG = this.fb.group<
			FormControlWrapper<ExternalAccountFormGroup>
		>({
			account: new FormControl(null, Validators.required),
		});
		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.externalAccountsService
			.getExternalAccountsOptions()
			.subscribe((options) => {
				this.options = options;
				this.loading = false;
			});
	};

	public save = () => {
		this.saving = true;
		const arg = this.externalAccountFG.controls.account.value;
		this.externalAccountsService
			.createExternalLink(this.userId, {
				id: arg.id,
				type: arg.type,
			})
			.subscribe({
				next: () => {
					this.saving = false;
					this.activeModal.close(true);
				},
				error: (err: MapResponseErrors) => {
					console.error(mergeStrings(err));
					this.toastr.error('There was an error when adding external account');
					this.saving = false;
				},
			});
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
