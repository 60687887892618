import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
	UserDataProfileModel,
	UserDataStudentModel,
} from 'src/lib/services/api/users/user-data.model';
import {
	hasKey,
	hasSomeKey,
	isNullOrEmptyString,
} from 'src/lib/utilities/compare';
import { IconTooltipComponent } from '../../../../../templates/global/icon-tooltip/icon-tooltip.component';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';
import { NgDompurifyPipe } from '../../../../../thirdparty/ng-dompurify/ng-dompurify.pipe';

@Component({
	selector: 'ae-profile-info-view',
	templateUrl: './profile-info-view.component.html',
	styleUrls: ['./profile-info-view.component.scss'],
	standalone: true,
	imports: [
		DatePipe,
		IconTooltipComponent,
		NgDompurifyPipe,
		SpinWhileDirective,
	],
})
export class ProfileInfoViewComponent {
	@Input() public profileData: UserDataProfileModel;
	@Input() public studentData: UserDataStudentModel;
	@Input() public canEditProfile: boolean;
	@Input() public loading: boolean;
	@Input() public enableEdit: () => void;
	@Input() public existingPendingChange: boolean;

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;

	public hasKey = hasKey;
	public hasSomeKey = hasSomeKey;

	public getMaidenName = () => {
		if (isNullOrEmptyString(this.profileData?.profile_maiden_name)) {
			return '';
		} else {
			return `(${this.profileData.profile_maiden_name})`;
		}
	};

	public welcomeVideo = (videoId: string): string | null => {
		if (videoId) {
			return `https://www.youtube.com/watch?v=${videoId}`;
		}
		return null;
	};
}
