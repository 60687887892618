import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StudentEventUpsertOptions } from 'src/lib/services/api/students/events/student-event-upsert-options.model';
import { StudentEventModel } from 'src/lib/services/api/students/events/student-event.model';
import { StudentsEventsService } from 'src/lib/services/api/students/events/students-events.service';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentEventStoreService {
	private fetchArgs: PagingArgument = {
		take: 1000,
		skip: 0,
		sort: { by: 'created', order: 'DESC' },
	};

	private _noteCache = new BehaviorCache<number, StudentEventModel[]>(
		(uid) =>
			this.studentsEventsService
				.getCurrentNotes(uid, this.fetchArgs)
				.pipe(map((r) => r.results ?? [])),
		'StudentEventStore NoteCache',
	);

	private _archivedNoteCache = new BehaviorCache<number, StudentEventModel[]>(
		(uid) =>
			this.studentsEventsService
				.getArchivedNotes(uid, this.fetchArgs)
				.pipe(map((r) => r.results ?? [])),
		'StudentEventStore ArchiveNoteCache',
	);

	private _optionCache = new BehaviorCache<number, StudentEventUpsertOptions>(
		(uid) => this.studentsEventsService.getEventUpsertOptions(uid),
		'StudentEventStore OptionCache',
	);

	constructor(private studentsEventsService: StudentsEventsService) {}

	/**
	 * Notes
	 */
	public notes$ = (uid: number): Observable<StudentEventModel[]> => {
		return this._noteCache.getCache(uid);
	};

	public refreshNotes = (uid: number): Promise<boolean> => {
		return this._noteCache.fetchData(uid, true);
	};

	/**
	 * Archived Notes
	 */
	public archivedNotes$ = (uid: number): Observable<StudentEventModel[]> => {
		return this._archivedNoteCache.getCache(uid);
	};

	public refreshArchivedNotes = (uid: number): Promise<boolean> => {
		return this._archivedNoteCache.fetchData(uid, true);
	};

	/**
	 * Options
	 */
	public options$ = (uid: number): Observable<StudentEventUpsertOptions> => {
		return this._optionCache.getCache(uid);
	};

	public refreshOptions = (uid: number): Promise<boolean> => {
		return this._optionCache.fetchData(uid, true);
	};
}
