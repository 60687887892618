import { KeyValuePipe, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { AsyncSubject, finalize, takeUntil } from 'rxjs';
import {
	LoginProgramModel,
	LoginService,
	SSOType as SSOTypes,
} from 'src/public.services';
import { SpinWhileDirective } from 'src/public.templates';
import { LoginState } from '../../login-state.enum';
import { LoginSaveService } from '../../services/login-save.service';
import { SsoButtonComponent } from '../sso-button/sso-button.component';

@Component({
	selector: 'gal-sso-login',
	standalone: true,
	imports: [
		KeyValuePipe,
		NgTemplateOutlet,
		SpinWhileDirective,
		SsoButtonComponent,
	],
	templateUrl: './sso-login.component.html',
	styleUrl: './sso-login.component.scss',
})
export class SsoLoginComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input({ required: true }) program: LoginProgramModel;
	@Input({ required: true }) destinationUrl: URL | null;
	@Output() setState = new EventEmitter<LoginState>();

	public loginState = LoginState;
	public ssoOptions = new Map<SSOTypes, string>();

	public loading = true;
	public saving = false;
	public errorLoading = false;

	constructor(
		private loginService: LoginService,
		private loginSaveService: LoginSaveService,
	) {}

	ngOnInit(): void {
		this.loginService
			.getSsoOptions(null, this.program.institute_unique_id)
			.pipe(
				finalize(() => {
					this.loading = false;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe({
				next: (x) => {
					Object.entries(x).forEach((value) => {
						if (value[1] != null) {
							this.ssoOptions.set(value[0] as SSOTypes, value[1]);
						}
					});
				},
				error: (err) => {
					this.errorLoading = true;
					console.error(err);
				},
			});
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	public goToSchoolSelect = () => {
		this.loginSaveService.setUniqueId('');
		this.setState.emit(this.loginState.schoolSelection);
	};
}
