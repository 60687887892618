import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, takeUntil } from 'rxjs';
import { ExternalAccountModel } from 'src/lib/services/api/external-accounts/external-account.model';
import { PermissionUserRolesModel } from 'src/lib/services/api/permission-manager/permission-user-roles.model';
import { UserUpdateRequestArgument } from 'src/lib/services/api/users/update-request/users-update-request.argument';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import {
	UserDataGAModel,
	UserDataProfileModel,
	UserDataUserModel,
} from 'src/lib/services/api/users/user-data.model';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { StringEnumOption } from 'src/lib/utilities/api/patterns/dynamic-fields/string-enum-option.model';
import { ExternalAccountCreationModalService } from 'src/lib/views/modals/external-account-creation-modal/external-account-creation-modal.service';
import { RoleEditModalService } from 'src/lib/views/modals/role-edit-modal/role-edit-modal.service';
import { GroupValidationDisplayComponent } from '../../../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../../templates/layout/group-validation/validation-error.directive';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';

export interface AccountFormModel {
	title: string;
	admin_type: string[];
	employee_id: string;
}

@Component({
	selector: 'ae-profile-account-edit',
	templateUrl: './profile-account-edit.component.html',
	styleUrls: ['./profile-account-edit.component.scss'],
	standalone: true,
	imports: [
		DatePipe,
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		NgSelectModule,
		ReactiveFormsModule,
		SpinWhileDirective,
		ValidationErrorDirective,
	],
})
export class ProfileAccountEditComponent implements OnInit {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public accountData: UserDataUserModel;
	@Input() public canEditAccount: boolean;
	@Input() public loading: boolean;
	@Input() public enableEdit: () => void;
	@Input() public existingPendingChange: boolean;
	@Input() public gaData: UserDataGAModel;
	@Input() public accountSaveListener: (forceRefresh: boolean) => void;
	@Input() public currentUserId: number;
	@Input() public profileData: UserDataProfileModel;
	@Input() public formattedAccessType: string[];
	@Input() public assignedStudents: string[];
	@Input() public canEditId: boolean;
	@Input() public canEditRoles: boolean;
	@Input() public canEditLinkedAccounts: boolean;
	@Input() public externalAccounts: ExternalAccountModel[];

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;

	public dropdownAdminType: StringEnumOption[] = [
		{ key: 'counselor', title: 'Counselor' },
		{ key: 'school_administrator', title: 'Administrator' },
		{ key: 'assistant', title: 'Assistant' },
	];
	public savingInfo: boolean = false;

	public accountFormGroup: FormGroup<FormControlWrapper<AccountFormModel>>;

	constructor(
		private fb: FormBuilder,
		private userRequestService: UsersUpdateRequestsService,
		private toastr: ToastrService,
		private roleEditModalService: RoleEditModalService,
		private externalAccountCreationModalService: ExternalAccountCreationModalService,
	) {}

	ngOnInit() {
		this.accountFormGroup = this.fb.group<FormControlWrapper<AccountFormModel>>(
			{
				admin_type: new FormControl(
					this.accountData?.admin_type ? this.accountData.admin_type : null,
					Validators.required,
				),
				title: new FormControl(this.profileData?.profile_user_title),
				employee_id: new FormControl(this.profileData?.profile_employee_id),
			},
		);
	}

	public save = () => {
		this.savingInfo = true;
		const formCtrls = this.accountFormGroup.controls;
		const profileContactArgs: UserUpdateRequestArgument = {
			user: {
				admin_type: formCtrls.admin_type.value,
			},
			profile: {
				profile_user_title: formCtrls.title.value,
				profile_employee_id: formCtrls.employee_id.value,
			},
		};
		this.accountFormGroup.disable();
		this.userRequestService
			.createUpdateRequest(this.currentUserId, profileContactArgs)
			.subscribe({
				next: (response) => {
					this.accountFormGroup.enable();
					this.savingInfo = false;
					if (response.success) {
						if (response.update_request) {
							this.toastr.success(
								'Account info update has been requested. Your changes will not be shown until they are approved.',
							);
						} else {
							this.toastr.success('Account info has been updated.');
						}
						this.accountSaveListener(true);
					} else {
						this.toastr.error(
							`There was an error when updating account info ${(
								response as any
							)?.error_messages.join('\n')}`,
						);
					}
				},
				error: () => {
					this.accountFormGroup.enable();
					this.savingInfo = false;
					this.toastr.error(`There was an error when updating account info`);
				},
			});
	};

	public editRoles = () => {
		const value = new PermissionUserRolesModel();
		value.name = `${this.profileData.profile_first_name} ${this.profileData.profile_last_name}`;
		value.roles = this.accountData.roles;
		value.uid = this.currentUserId;
		this.roleEditModalService
			.openModal$(value)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((r) => {
				if (r) {
					this.accountSaveListener(true);
				}
			});
	};

	public addExternalAccounts = () => {
		this.externalAccountCreationModalService
			.openModal$(this.currentUserId)
			.subscribe((r) => {
				if (r) {
					this.accountSaveListener(true);
				}
			});
	};
}
