import { Type } from 'class-transformer';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	CurriculumMappingCourseEndorsementModel,
	CurriculumMappingCourseEndorsementModel_ClassTransform_Type,
} from './courses/curriculum-mapping-course.model';

export class CurriculumMappingSectionCatalogModel {
	catalog_id: number;
	title: string;
}

export function CurriculumMappingSectionCatalogModel_ClassTransform_Type() {
	return CurriculumMappingSectionCatalogModel;
}

export class CurriculumMappingSectionModel {
	lms_id: number;
	lms_title: string;
	lms_url: string;
	lms_section_id: string;
	section_id: number;
	section_title: string;
	version_code: string;
	proctor_password: string;
	no_final: boolean;
	non_credit: boolean;
	registration_type: number;
	@Type(CurriculumMappingCourseEndorsementModel_ClassTransform_Type)
	required_endorsements: CurriculumMappingCourseEndorsementModel[];
	@Type(CurriculumMappingSectionCatalogModel_ClassTransform_Type)
	catalogs: CurriculumMappingSectionCatalogModel[];
	alternate_contact_relationship_rtid: number;
	requires_parental_consent: boolean;
}

export class CurriculumMappingSectionListItemModel {
	section_title: string;
	section_id: number;
	version_title: string;
	version_id: number;
	version_code: string;
	course_id: number;
	course_title: string;
	course_number: string;
	min_grade_level: number;
	max_grade_level: number;
	lms_id: number;
	lms_title: string;
	lms_url: string;
	term: string;
	catalog_count: number;
	teacher_count: number;
	required_endorsements_count: number;
}

export function CurriculumMappingSectionListItemModel_ClassTransform_Type() {
	return CurriculumMappingSectionListItemModel;
}

export class CurriculumMappingSectionListResponseModel {
	count: number;
	@Type(CurriculumMappingSectionListItemModel_ClassTransform_Type)
	sections: CurriculumMappingSectionListItemModel[];
}

export class CurriculumMappingSectionLmsModel {
	lms_id: number;
	lms_title: string;
}

export function CurriculumMappingSectionLmsModel_ClassTransform_Type() {
	return CurriculumMappingSectionLmsModel;
}

export class CurriculumMappingSectionTermModel {
	term_number: number;
	term: string;
	term_type: string;
}

export function CurriculumMappingSectionTermModel_ClassTransform_Type() {
	return CurriculumMappingSectionTermModel;
}

export class CurriculumMappingSectionLmsSectionListModel {
	lms_section_id: string;
	lms_section_title: string;
}

export function CurriculumMappingSectionLmsSectionListModel_ClassTransform_Type() {
	return CurriculumMappingSectionLmsSectionListModel;
}

//
export class CurriculumMappingSectionLmsRelationshipModel {
	rtid: number;
	name: string;
}

export function CurriculumMappingSectionLmsRelationshipModel_ClassTransform_Type() {
	return CurriculumMappingSectionLmsRelationshipModel;
}

export class CurriculumMappingSectionLmsRegistrationTypeModel {
	key: string | number;
	name: string;
}

export function CurriculumMappingSectionLmsRegistrationTypeModel_ClassTransform_Type() {
	return CurriculumMappingSectionLmsRegistrationTypeModel;
}

export class CurriculumMappingSectionOptionsModel {
	@Type(CurriculumMappingSectionLmsModel_ClassTransform_Type)
	lms_list: CurriculumMappingSectionLmsModel[];
	@Type(CurriculumMappingSectionLmsSectionListModel_ClassTransform_Type)
	lms_section_list: CurriculumMappingSectionLmsSectionListModel[];
	@Type(CurriculumMappingSectionTermModel_ClassTransform_Type)
	term_options: CurriculumMappingSectionTermModel[];
	@Type(CurriculumMappingSectionLmsRelationshipModel_ClassTransform_Type)
	alternate_contact_relationship_rtid: CurriculumMappingSectionLmsRelationshipModel[];
	@Type(CurriculumMappingSectionLmsRegistrationTypeModel_ClassTransform_Type)
	registration_type: CurriculumMappingSectionLmsRegistrationTypeModel[];
}

export class CurriculumMappingSectionMappingPossibleTeacherModel {
	name: string;
	id: number;
}

export class CurriculumMappingSectionMappingGroupOptionModel {
	name: string;
	title: string;
}

export function CurriculumMappingSectionMappingGroupOptionModel_ClassTransform_Type() {
	return CurriculumMappingSectionMappingGroupOptionModel;
}

export class CurriculumMappingSectionMappingGroupsModel {
	collection: string;
	collection_title: string;
	@Type(CurriculumMappingSectionMappingGroupOptionModel_ClassTransform_Type)
	options: CurriculumMappingSectionMappingGroupOptionModel[];
}

export interface CurriculumMappingSectionPagingArgument {
	catalog_id?: SectionCatalogValue;
	section_title?: PagingSearchFieldArgument;
	section_id?: PagingSearchFieldArgument;
	version_code?: PagingSearchFieldArgument;
	course_title?: PagingSearchFieldArgument;
	grade_string?: PagingSearchFieldArgument;
	lms_title?: PagingSearchFieldArrayArgument;
	term_name?: PagingSearchFieldArrayArgument;
	catalog_count?: PagingSearchFieldArgument;
	teacher_count?: PagingSearchFieldArgument;
	required_endorsements_count?: PagingSearchFieldArgument;
}

export interface SectionCatalogValue {
	value: number;
}

export interface CurriculumMappingSectionEditArgument {
	lms_id?: number;
	lms_section_id?: string;
	section_id?: number;
	proctor_password?: string;
	no_final?: boolean;
	non_credit?: boolean;
	registration_type?: number;
	alternate_contact_relationship_rtid?: number;
	requires_parental_consent?: boolean;
}
