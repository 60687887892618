import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	OnDestroy,
	OnInit,
	Signal,
	WritableSignal,
	computed,
	signal,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
	AsyncSubject,
	combineLatest,
	finalize,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs';
import { Permits } from 'src/lib/constants/permissions';
import {
	NotificationUpdate,
	NotificationUpdateArgument,
	ReportUpdate,
} from 'src/lib/services/api/users/user-notification.argument';
import {
	UserNotificationModel,
	UserReportModel,
} from 'src/lib/services/api/users/user-notification.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { groupBy } from 'src/lib/utilities/array';
import { convertToString, tryParseInt } from 'src/lib/utilities/convert';
import { firstBy } from 'thenby';
import { InputCheckboxComponent } from '../../../../templates/controls/input-checkbox/input-checkbox.component';
import { WaitSpinnerComponent } from '../../../../templates/global/wait-spinner/wait-spinner.component';
import { SpinWhileDirective } from '../../../../templates/layout/spin-while/spin-while.directive';

interface NotificationGroup {
	hasPreference: boolean;
	preference: boolean;
}

interface ReportGroup {
	subscribed: boolean;
}

@Component({
	selector: 'ae-profile-subscriptions',
	templateUrl: './profile-subscriptions.component.html',
	styleUrls: ['./profile-subscriptions.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		InputCheckboxComponent,
		NgbTooltip,
		NgClass,
		NgTemplateOutlet,
		ReactiveFormsModule,
		SpinWhileDirective,
		WaitSpinnerComponent,
	],
})
export class ProfileSubscriptionsComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	protected loading: boolean;
	protected saving: boolean = false;
	protected canEdit: boolean = false;

	protected editingNotifications = false;
	protected editingReports = false;

	protected notificationsFormGroup: FormGroup<
		Record<string, FormGroup<FormControlWrapper<NotificationGroup>>>
	>;
	protected reportsFormGroup: FormGroup<
		Record<string, FormGroup<FormControlWrapper<ReportGroup>>>
	>;

	protected notifications: WritableSignal<UserNotificationModel[]> = signal([]);
	protected requiredNotifications: Signal<UserNotificationModel[]> = computed(
		() => this.notifications().filter((x) => x.is_required),
	);
	protected notRequiredNotifications: Signal<UserNotificationModel[]> =
		computed(() => this.notifications().filter((x) => !x.is_required));
	protected viewNotRequiredNotifications: Signal<UserNotificationModel[]> =
		computed(() =>
			this.notRequiredNotifications().filter((v) => {
				return (
					(v.preference_send_by_default && v.send_by_default) ||
					(v.preference_send_by_default == null && v.send_by_default) ||
					v.is_required
				);
			}),
		);

	protected reports: WritableSignal<UserReportModel[]> = signal([]);
	protected groupedViewReports: Signal<UserReportModel[][]> = computed(() =>
		this.groupReports(this.reports().filter((x) => x.subscribed)),
	);
	protected groupedEditReports: Signal<UserReportModel[][]> = computed(() =>
		this.groupReports(this.reports()),
	);

	private userProfileId: number;

	constructor(
		private fb: FormBuilder,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private usersService: UsersService,
		private permissionService: PermissionStoreService,
		private userStoreService: UserStoreService,
	) {}

	ngOnInit() {
		this.loading = true;

		combineLatest([this.route.params, this.permissionService.getFieldSet$()])
			.pipe(
				tap(([params, permissions]) => {
					this.userProfileId = tryParseInt(params.userId);
					if (this.userProfileId === this.userStoreService.currentUserUid) {
						this.canEdit = permissions.canDo(
							Permits['ga_event|edit own user notification settings'],
						);
					} else {
						this.canEdit = permissions.canDo(
							Permits['ga_event|edit user notification settings'],
						);
					}
				}),
				switchMap(() =>
					this.usersService.getUserNotifications(this.userProfileId),
				),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((v) => {
				this.notifications.set(
					v.notifications.sort(firstBy((x) => x.instance_title, 1)),
				);
				this.reports.set(v.reports.sort(firstBy((x) => x.report_title, 1)));
				this.loading = false;
			});
	}

	public enableEditNotification = () => {
		if (!this.editingNotifications) {
			this.editingNotifications = true;

			this.notificationsFormGroup = this.fb.group({});

			this.notifications().forEach((o) => {
				const g = this.fb.group<FormControlWrapper<NotificationGroup>>({
					preference: new FormControl({
						value: o.preference_send_by_default ?? o.send_by_default,
						disabled: o.is_required,
					}),
					hasPreference: new FormControl({
						value: o.preference_send_by_default != null,
						disabled: o.is_required,
					}),
				});

				g.controls.preference.valueChanges.subscribe(() => {
					g.controls.hasPreference.setValue(true);
				});

				this.notificationsFormGroup.addControl(
					convertToString(o.notification_id),
					g,
				);
			});
		} else {
			this.editingNotifications = false;
		}
	};

	public enableEditReports = () => {
		if (!this.editingReports) {
			this.editingReports = true;

			this.reportsFormGroup = this.fb.group({});

			this.reports().forEach((o) => {
				const g = this.fb.group<FormControlWrapper<ReportGroup>>({
					subscribed: new FormControl(o.subscribed),
				});

				this.reportsFormGroup.addControl(convertToString(o.schedule_id), g);
			});
		} else {
			this.editingReports = false;
		}
	};

	public resetAllNotifications = () => {
		this.notifications().forEach((data) => this.resetNotification(data));
	};

	public resetNotification = (notification: UserNotificationModel) => {
		if (!notification.is_required) {
			this.getNotificationGroup(notification.notification_id).patchValue({
				preference: notification.send_by_default,
				hasPreference: false,
			});
		}
	};

	public getNotificationGroup = (id: number) => {
		return this.notificationsFormGroup.get(convertToString(id)) as FormGroup<
			FormControlWrapper<NotificationGroup>
		>;
	};

	public getReportGroup = (id: number) => {
		return this.reportsFormGroup.get(convertToString(id)) as FormGroup<
			FormControlWrapper<ReportGroup>
		>;
	};

	public save = (type: 'notification' | 'report') => {
		this.saving = true;
		const args: NotificationUpdateArgument = {};

		if (type === 'notification') {
			args.notifications = this.notifications()
				.filter((x) => !x.is_required)
				.map((data) => {
					const val = this.getNotificationGroup(data.notification_id).value;

					return {
						notification_id: data.notification_id,
						preference_send_by_default: val.hasPreference
							? val.preference
							: null,
					} as NotificationUpdate;
				});
		}
		if (type === 'report') {
			args.reports = this.reports().map((data) => {
				const val = this.getReportGroup(data.schedule_id).value;

				return {
					schedule_id: data.schedule_id,
					subscribed: val.subscribed,
				} as ReportUpdate;
			});
		}

		this.usersService
			.setUserNotifications(this.userProfileId, args)
			.pipe(
				finalize(() => {
					this.saving = false;
				}),
			)
			.subscribe((v) => {
				this.notifications.set(
					v.notifications.sort(firstBy((x) => x.instance_title, 1)),
				);
				this.reports.set(v.reports.sort(firstBy((x) => x.report_title, 1)));

				if (type === 'notification') {
					this.editingNotifications = false;
				}
				if (type === 'report') {
					this.editingReports = false;
				}

				this.toastr.success(`Updated ${type} settings`, 'Admin Subscriptions');
			});
	};

	private groupReports = (arr: UserReportModel[]) => {
		return Object.values(groupBy(arr, 'report_id'));
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
