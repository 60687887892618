import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { UserModalModel } from 'src/lib/services/api/users/modals/user-modal.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { MapResponseErrors } from 'src/lib/utilities/api/map-response';
import { mergeStrings } from 'src/lib/utilities/array';
import { hasValue } from 'src/lib/utilities/compare';
import { commonValidators } from 'src/lib/utilities/forms';
import { UserNamePipe } from '../../../pipes/user-name.pipe';
import { PasswordRandomizerComponent } from '../../../templates/controls/password-randomizer/password-randomizer.component';
import { ModalCloseTimeoutComponent } from '../../../templates/global/modal-close-timeout/modal-close-timeout.component';
import { WaitSpinnerComponent } from '../../../templates/global/wait-spinner/wait-spinner.component';
import { GroupValidationDisplayComponent } from '../../../templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../templates/layout/group-validation/validation-error.directive';
import { SpinWhileDirective } from '../../../templates/layout/spin-while/spin-while.directive';

interface PasswordForm {
	current: string;
	updated: string;
	confirmed: string;
}

@Component({
	selector: 'ae-prompt-password-modal',
	templateUrl: './prompt-password-modal.component.html',
	styleUrls: ['./prompt-password-modal.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		ModalCloseTimeoutComponent,
		PasswordRandomizerComponent,
		ReactiveFormsModule,
		SpinWhileDirective,
		UserNamePipe,
		ValidationErrorDirective,
		WaitSpinnerComponent,
	],
})
export class PromptPasswordModalComponent {
	@Input() data: UserModalModel;

	public uid: number;

	public passwordForm: FormGroup<FormControlWrapper<PasswordForm>>;
	public title: string = null;

	public saving = false;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private fb: FormBuilder,
		private toastr: ToastrService,
		private userStoreService: UserStoreService,
		private usersService: UsersService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { data: UserModalModel }): void => {
		// SET DATA
		this.data = data.data;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.title = this.data?.modal_title ?? 'Update Password';
		this.uid = this.userStoreService.currentUserUid;

		this.passwordForm = this.fb.group<FormControlWrapper<PasswordForm>>(
			{
				current: new FormControl(null, [Validators.required]),
				updated: new FormControl(null, [
					Validators.required,
					Validators.minLength(8),
					Validators.maxLength(1000),
					commonValidators.passwordForbiddenValues,
				]),
				confirmed: new FormControl(null, [Validators.required]),
			},
			{ validators: [this.confirmedValidator, this.sameCurrentValidator] },
		);
	};

	confirmedValidator: ValidatorFn = (
		group: FormGroup,
	): ValidationErrors | null => {
		const values = group.value;
		return !group.controls.confirmed.dirty ||
			values.confirmed === values.updated
			? null
			: { passwordMismatch: true };
	};

	sameCurrentValidator: ValidatorFn = (
		group: FormGroup,
	): ValidationErrors | null => {
		const values = group.value;

		if (hasValue(values.current) && values.current === values.updated) {
			return { sameAsCurrent: 'You cannot reuse the same password' };
		} else return null;
	};

	save = () => {
		this.saving = true;

		this.usersService
			.updateUserEmailPassword(
				this.uid,
				this.passwordForm.controls.updated.value,
				null,
				this.passwordForm.controls.current.value,
			)
			.pipe(finalize(() => (this.saving = false)))
			.subscribe({
				next: (response) => {
					if (response.success) {
						this.toastr.success(`Password updated`, this.title);
						this.activeModal.close(true);
					} else {
						this.toastr.error('Current Password is invalid');
						this.passwordForm.controls.current.setErrors({
							passwordNotValid: true,
						});
					}
				},
				error: (err: MapResponseErrors) => {
					this.toastr.error(mergeStrings(err), this.title);
				},
			});
	};
}
