import { Transform, Type } from 'class-transformer';
import {
	integerTransformer,
	stringTransformer,
} from 'src/lib/utilities/api/class-transformer-types';

export class EnrollmentInfoStringOptionModel {
	@Transform(stringTransformer)
	key: string;
	title: string;
}

export function EnrollmentInfoStringOptionModel_ClassTransformType() {
	return EnrollmentInfoStringOptionModel;
}

export class EnrollmentInfoNumberOptionModel {
	@Transform(integerTransformer)
	key: number;
	title: string;
}

export function EnrollmentInfoDropoutOptionModel_ClassTransformType() {
	return EnrollmentInfoNumberOptionModel;
}

export class StudentEnrollmentInfoOptionsModel {
	title: string;
	type: string;
	required: string;
}

export class StudentEnrollmentStringInfoOptionsModel extends StudentEnrollmentInfoOptionsModel {
	@Type(EnrollmentInfoStringOptionModel_ClassTransformType)
	options: EnrollmentInfoStringOptionModel[];
}

export function StudentEnrollmentStringInfoOptionsModel_ClassTransformType() {
	return StudentEnrollmentStringInfoOptionsModel;
}

export class StudentEnrollmentNumberInfoOptionsModel extends StudentEnrollmentInfoOptionsModel {
	@Type(EnrollmentInfoDropoutOptionModel_ClassTransformType)
	options: EnrollmentInfoNumberOptionModel[];
}

export function StudentEnrollmentNumberInfoOptionsModel_ClassTransformType() {
	return StudentEnrollmentNumberInfoOptionsModel;
}

export class StudentEnrollmentInfoOptionsResponseModel {
	@Type(StudentEnrollmentStringInfoOptionsModel_ClassTransformType)
	iep_504: StudentEnrollmentStringInfoOptionsModel;
	@Type(StudentEnrollmentNumberInfoOptionsModel_ClassTransformType)
	dropout_reasons: StudentEnrollmentNumberInfoOptionsModel;
	@Type(StudentEnrollmentNumberInfoOptionsModel_ClassTransformType)
	esl_check: StudentEnrollmentNumberInfoOptionsModel;
	@Type(StudentEnrollmentStringInfoOptionsModel_ClassTransformType)
	computer_access_plan: StudentEnrollmentStringInfoOptionsModel;
}
