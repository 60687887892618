import { Component, OnInit } from '@angular/core';
import { UserDataContactModel } from 'src/lib/services/api/users/user-data.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { convertToInt } from 'src/lib/utilities/convert';
import { firstBy } from 'thenby';

import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { StudentsContactsService } from 'src/lib/services/api/students/contacts/students-contacts.service';
import { UserUpdateRequestOptions } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { hasKey } from 'src/lib/utilities/compare';
import { ProfileContactEditComponent } from './profile-contact-edit/profile-contact-edit.component';
import { ProfileContactViewComponent } from './profile-contact-view/profile-contact-view.component';

@Component({
	selector: 'ae-profile-contact',
	templateUrl: './profile-contact.component.html',
	styleUrls: ['./profile-contact.component.scss'],
	standalone: true,
	imports: [ProfileContactEditComponent, ProfileContactViewComponent],
})
export class ProfileContactComponent implements OnInit {
	public loading: boolean = true;
	public errorLoading: boolean = false;
	public savingInfo: boolean = false;

	public editEnabled: boolean = false;
	public canEditContact: boolean = false;

	public currentUserId: number;

	public contactData: UserDataContactModel;
	public phoneOptions: Map<string, string>;
	public contactOptions: UserUpdateRequestOptions;

	public existingPendingChange: boolean = false;

	constructor(
		private userService: UsersService,
		private route: ActivatedRoute,
		private userRequestService: UsersUpdateRequestsService,
		private studentsContactsService: StudentsContactsService,
	) {}

	ngOnInit() {
		this.route.params.subscribe((p) => {
			this.currentUserId = convertToInt(p.userId);
			this.getContactInfo();
		});
	}

	public enableEdit = () => {
		this.editEnabled = !this.editEnabled;
	};

	public resetContactForm = (forceRefresh: boolean = true) => {
		this.editEnabled = false;
		if (forceRefresh) {
			this.getContactInfo();
		}
	};

	public getContactInfo = () => {
		this.loading = true;

		combineLatest([
			this.userService.getUserData(this.currentUserId),
			this.studentsContactsService.getContactOptions(),
			this.userRequestService.getPendingRequests(this.currentUserId),
			this.userRequestService.getRequestsOptions(this.currentUserId),
		]).subscribe({
			next: ([userData, options, requests, requestOptions]) => {
				this.contactData = userData.contact_field;
				this.phoneOptions = options.phone_type;
				this.contactOptions = requestOptions.allowed_updates;
				this.existingPendingChange =
					requests.pending_update_requests?.length > 0;

				this.canEditContact =
					hasKey(this.contactOptions.contact_field.address, 'subfields') ||
					hasKey(this.contactOptions.contact_field, 'phone') ||
					hasKey(this.contactOptions.contact_field, 'messenger');

				// Sort and Filter by weight and active
				if (this.contactData.phone) {
					this.contactData.phone = this.contactData.phone
						.filter((phone) => phone.active)
						.sort(firstBy((x) => x.weight));
				}

				if (this.contactData.email) {
					this.contactData.ssoEmail = this.contactData.email
						.filter((email) => email.active && !email.prim)
						.sort(firstBy((x) => x.weight));

					this.contactData.email = this.contactData.email.filter(
						(email) => email.active && email.prim,
					);
				}

				if (this.contactData.address) {
					this.contactData.address = this.contactData.address
						.filter((address) => address.active)
						.sort(firstBy((x) => x.weight));
				}

				if (this.contactData.messenger) {
					this.contactData.messenger = this.contactData.messenger
						.filter((messaging) => messaging.active)
						.sort(firstBy((x) => x.weight));
				}

				this.loading = false;
			},
			error: (errors) => {
				console.error(mergeStrings(errors));
				this.errorLoading = true;
			},
		});
	};
}
