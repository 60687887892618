import { KeyValuePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'ae-form-error-popover',
	templateUrl: './form-error-popover.component.html',
	styleUrls: ['./form-error-popover.component.scss'],
	standalone: true,
	imports: [KeyValuePipe, NgbPopover],
})
export class FormErrorPopoverComponent {
	@Input({ required: true }) ctrl: AbstractControl;
	@Input() showErrors: boolean = null;
	@Input() errorMessages: Record<string, string> = {};

	hasErrors = () => {
		if (typeof this.showErrors === 'boolean') {
			return this.showErrors;
		} else {
			return (
				this.ctrl && this.ctrl.invalid && (this.ctrl.dirty || this.ctrl.touched)
			);
		}
	};

	convertObject = (value: any, key: string): string => {
		if (typeof value === 'string') {
			return value;
		} else {
			return `${key}: ${JSON.stringify(value)}`;
		}
	};
}
