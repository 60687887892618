import {
	Component,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	forwardRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncSubject, Subscription, takeUntil } from 'rxjs';
import { USA_TIMEZONES } from 'src/lib/constants/constants';
import { convertToString } from 'src/lib/utilities/convert';

@Component({
	selector: 'ae-timezone-select',
	templateUrl: './timezone-select.component.html',
	styleUrls: ['./timezone-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TimezoneSelectComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [FormsModule, NgSelectModule, ReactiveFormsModule],
})
export class TimezoneSelectComponent
	implements ControlValueAccessor, OnDestroy, OnInit
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@HostBinding('class.custom-form-control') customFormControl = true;

	@Input() public timezones: (
		| string
		| { key: string; value?: string; label?: string; title?: string }
	)[];
	@Input() public selectedTimezone: string | { key: string; value: string };
	@Input() public placeholder: string;
	@Input() public keyName: string;
	@Input() public labelName: string;
	@Input() public clearable: boolean;

	private _touchFunction: () => void;
	private _changeFunction: (
		value:
			| string
			| { key: string; value?: string; label?: string; title?: string },
	) => void = () => null;
	private _changeWatcher: Subscription;

	public ctrl: FormControl<
		string | { key: string; value?: string; label?: string; title?: string }
	>;

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		this.ctrl = this.fb.control(null);
		this.watchForChange();
	}

	// ControlValueAccessor
	public writeValue(
		val:
			| string
			| { key: string; value?: string; label?: string; title?: string },
	): void {
		try {
			this.stopWatchForChange();
			this.ctrl.setValue(this.getTimezoneValue(val));
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;
		this._changeWatcher = this.ctrl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (this._changeFunction) {
					this._changeFunction(this.getTimezoneValue(val));
				}
				if (this._touchFunction) {
					this._touchFunction();
				}
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	groupByFn = (tz: unknown) => {
		return this.isUSTimezone(tz);
	};

	groupValueFn = (_: boolean, arr: any[]) => {
		if (this.isUSTimezone(arr[0])) {
			return { name: 'Common US Timezones' };
		}
		return { name: 'Other' };
	};

	public isUSTimezone = (tzObj: unknown | string) => {
		let o = tzObj;
		if (this.keyName) {
			o = tzObj[this.keyName];
		}

		return (
			USA_TIMEZONES.find(
				(tz) => tz.toLowerCase() === (o as string).toLowerCase(),
			) != null
		);
	};

	public customSearchFn(
		term: string,
		item:
			| string
			| { key: string; value?: string; label?: string; title?: string },
	) {
		term = term.toLocaleLowerCase();
		return (
			item['key']?.toLocaleLowerCase().includes(term) ||
			item['label']?.toLocaleLowerCase().includes(term) ||
			item['value']?.toLocaleLowerCase().includes(term) ||
			convertToString(item).toLowerCase().includes(term)
		);
	}

	private getTimezoneValue = (
		val:
			| string
			| { key: string; value?: string; label?: string; title?: string },
	) => {
		const tz = this.keyName
			? this.timezones.find(
					(timezone) =>
						timezone[this.keyName] === val ||
						timezone[this.keyName] === val[this.keyName],
				)
			: val;
		return tz ? (tz[this.keyName] ?? tz) : null;
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
