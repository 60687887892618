<!-- eslint-disable @angular-eslint/template/no-any -->
@if (inputControl) {
	<div class="d-flex validate-include-children flex-nowrap" container="body">
		<input
			class="form-control form-control-color border-end-0 squared-end"
			title="Color Picker"
			type="color"
			id="{{ id }}.picker"
			[value]="colorValue"
			(change)="writeValue($any($event.srcElement).value)"
		/>
		<input
			class="form-control squared-start"
			type="text"
			[formControl]="inputControl"
			[id]="id"
		/>
	</div>
}
