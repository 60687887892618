import { NgClass } from '@angular/common';
import {
	Component,
	forwardRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	NgbDropdown,
	NgbDropdownButtonItem,
	NgbDropdownItem,
	NgbDropdownMenu,
	NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { FormControlWrapper, TypedValidatorFn } from 'src/lib/types/forms.def';
import { SearchOperators } from 'src/lib/utilities/api/patterns/pagination/search-operators.enum';
import { hasValue, isInteger, isNumber } from 'src/lib/utilities/compare';
import { convertToInt } from 'src/lib/utilities/convert';
import { SearchOperatorIconComponent } from '../../global/search-operator-icon/search-operator-icon.component';

export interface EqualityComparerNumberInputValue {
	operator: SearchOperators;
	operand: number;
}

@Component({
	selector: 'ae-equality-comparer-number-input',
	templateUrl: './equality-comparer-number-input.component.html',
	styleUrls: ['./equality-comparer-number-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EqualityComparerNumberInputComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => EqualityComparerNumberInputComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [
		FormsModule,
		NgbDropdown,
		NgbDropdownButtonItem,
		NgbDropdownItem,
		NgbDropdownMenu,
		NgbDropdownToggle,
		NgClass,
		ReactiveFormsModule,
		SearchOperatorIconComponent,
	],
})
export class EqualityComparerNumberInputComponent
	implements ControlValueAccessor, OnChanges, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@HostBinding('class.custom-form-control') customFormControl = true;

	@Input() operators: SearchOperators[] = [
		SearchOperators.Equals,
		SearchOperators.GreaterThanOrEqual,
		SearchOperators.LessThanOrEqual,
		SearchOperators.GreaterThan,
		SearchOperators.LessThan,
	];

	@Input() id: string = null;

	@Input() min: number = null;
	@Input() max: number = null;
	@Input() step: number = 1;

	public disabled: boolean = false;
	protected hideInput: boolean = false;
	public ecForm: FormGroup<
		FormControlWrapper<EqualityComparerNumberInputValue>
	>;

	private _touchFunction: () => void;
	private _validateFns = new Map<string, TypedValidatorFn<number>>();
	private _changeFunction: (
		value: Partial<EqualityComparerNumberInputValue>,
	) => void = () => null;
	private _changeWatcher: Subscription;

	constructor(private fb: FormBuilder) {
		this.init();
	}

	private init = () => {
		this.ecForm = this.fb.group<
			FormControlWrapper<EqualityComparerNumberInputValue>
		>({
			operator: new FormControl(null, Validators.required),
			operand: new FormControl(null, Validators.required),
		});

		this.ecForm.controls.operator.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => this.checkOperatorState(v));
		this.ecForm.controls.operator.setValue(this.operators[0]);

		this._validateFns.set('isnumber', (ctrl: FormControl) => {
			if (ctrl.value == null || isNumber(ctrl.value)) {
				return null;
			} else {
				return { isnumber: true };
			}
		});

		this.watchForChange();
	};

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.ecForm.valueChanges
			.pipe(
				distinctUntilChanged((x, y) => {
					return x.operand === y.operand && x.operator === y.operator;
				}),
				filter((val) => {
					if (
						isInteger(this.step) &&
						hasValue(val.operand) &&
						!isInteger(val.operand)
					) {
						this.ecForm.controls.operand.setValue(convertToInt(val.operand));
						return false;
					} else if (
						!isInteger(this.step) &&
						hasValue(val.operand) &&
						!isNumber(val.operand)
					) {
						this.ecForm.controls.operand.setValue(convertToInt(val.operand));
						return false;
					} else return true;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((val) => {
				if (this._changeFunction) {
					if (this.ecForm.valid) {
						this._changeFunction(val);
					} else {
						this._changeFunction(null);
					}
				}

				if (this._touchFunction) {
					this._touchFunction();
				}
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	private forceOperatorToBeValid = () => {
		if (this.operators.indexOf(this.ecForm.controls.operator.value) === -1) {
			this.ecForm.controls.operator.setValue(this.operators[0]);
		}
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes.operators) {
			if (!(this.operators.length > 0)) {
				throw new Error(
					'EqualityComparerInputComponent.operators must be an array with values',
				);
			}

			this.forceOperatorToBeValid();
		}

		if (changes.max) {
			if (changes.max.currentValue == null) {
				this._validateFns.set('max', null);
			} else {
				this._validateFns.set('max', Validators.max(changes.max.currentValue));
			}
		}

		if (changes.min) {
			if (changes.min.currentValue == null) {
				this._validateFns.set('min', null);
			} else {
				this._validateFns.set('min', Validators.min(changes.min.currentValue));
			}
		}
	}

	private checkOperatorState = (operator: SearchOperators) => {
		if (
			operator === SearchOperators.IsNull ||
			operator === SearchOperators.IsNotNull
		) {
			this.ecForm.controls.operand.disable();
			this.hideInput = true;
		} else {
			this.ecForm.controls.operand.enable();
			this.hideInput = false;
		}
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	public selectOperator = (op: SearchOperators) => {
		this.ecForm.controls.operator.setValue(op);
	};

	// Implementing NG_VALIDATORS
	public validate = () => {
		const errors = {};

		this._validateFns.forEach((fn) => {
			if (!fn) return;

			const error = fn(this.ecForm.controls.operand);
			if (error) {
				const key = Object.keys(error)[0];
				errors[key] = error[key];
			}
		});

		return errors;
	};

	// Implementing ControlValueAccessor
	public writeValue(val: EqualityComparerNumberInputValue): void {
		const currentVal =
			val ||
			({
				operand: null,
				operator: SearchOperators.Equals,
			} as EqualityComparerNumberInputValue);

		try {
			this.stopWatchForChange();
			this.ecForm.setValue(currentVal);

			this.forceOperatorToBeValid();
		} catch (e) {
			throw new Error(
				`EqualityComparerInputComponent.writeValue could not set value. INNER EXCEPTION: ${e}`,
			);
		} finally {
			this.watchForChange();
		}
	}
	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.stopWatchForChange();

		if (this.disabled) this.ecForm.disable();
		else {
			this.ecForm.enable();
			this.checkOperatorState(this.ecForm.controls.operator.value);
		}

		this.watchForChange();
	}
}
