import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { AsyncSubject, takeUntil } from 'rxjs';
import { IfEmptyPipe, PhoneNumberPipe } from 'src/public.pipes';
import { HelpDeskInfoModel, LoginService } from 'src/public.services';
import {
	LabeledDisplayComponent,
	SpinWhileDirective,
} from 'src/public.templates';
import { LoginState } from '../../login-state.enum';

@Component({
	selector: 'gal-need-help',
	standalone: true,
	imports: [
		IfEmptyPipe,
		LabeledDisplayComponent,
		PhoneNumberPipe,
		SpinWhileDirective,
	],
	templateUrl: './need-help.component.html',
	styleUrl: './need-help.component.scss',
})
export class NeedHelpComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Output() public setState = new EventEmitter<LoginState>();

	constructor(private loginService: LoginService) {}

	public helpDeskInfo: HelpDeskInfoModel;

	public loading = true;
	public errorLoading = false;

	ngOnInit(): void {
		this.loginService
			.getHelpDeskInfo()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: (r) => {
					this.helpDeskInfo = r;
				},
				error: () => {
					this.errorLoading = true;
				},
			});
		this.loading = false;
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
