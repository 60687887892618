import { Observable } from 'rxjs';

export interface IPaginationTableApi<T> {
	/**
	 * Use to tell the table that the data it has is out of data, such as a filter has changed or data has mutated.
	 *
	 * @param keepPage boolean, default = false
	 * @param keepSelection boolean, default = false
	 * @param silent boolean, default = false
	 */
	refresh: (opts?: {
		keepPage?: boolean;
		keepSelection?: boolean;
		silent?: boolean;
	}) => Promise<unknown>;
	/** Returns all of the keys for the entire dataset. If this is defined, select all functionality is enabled. */
	getSelectedKeys: () => any[];
	/** Clear all selected keys */
	clearSelectedKeys: () => void;
	/** The function that executes when the number of checked checkboxes changes. Returns the newCount and the oldCount */
	selectionChange$: Observable<{ newCount: number; oldCount: number }>;
	/** Returns all of the currently visible columns. */
	getCurrentColumns: () => string[];
	/** The function that executes when the visible columns changes. Returns the oldColumns and the newColumns */
	columnsChange$: Observable<{ newColumns: string[]; oldColumns: string[] }>;
	/** The function that returns a copy of the current table items. */
	currentItems: () => T[];
	/** Function recalculates the pgtable UI */
	detectChanges: () => void;
	/** Function to switch to a configuration realm */
	switchRealm: (realm: { id: string; name: string } | null) => void;
}

export class PaginationTableApi<T = unknown> implements IPaginationTableApi<T> {
	constructor({
		refresh,
		getSelectedKeys,
		clearSelectedKeys,
		selectionChange$,
		getCurrentColumns,
		columnsChange$,
		currentItems,
		detectChanges,
		switchRealm,
	}: IPaginationTableApi<T>) {
		this.refresh = refresh;
		this.getSelectedKeys = getSelectedKeys;
		this.clearSelectedKeys = clearSelectedKeys;
		this.selectionChange$ = selectionChange$;
		this.getCurrentColumns = getCurrentColumns;
		this.columnsChange$ = columnsChange$;
		this.currentItems = currentItems;
		this.detectChanges = detectChanges;
		this.switchRealm = switchRealm;
	}

	public refresh: (opts?: {
		keepPage?: boolean;
		keepSelection?: boolean;
		silent?: boolean;
	}) => Promise<unknown>;
	public getSelectedKeys: () => any[];
	public clearSelectedKeys: () => void;
	public selectionChange$: Observable<{ newCount: number; oldCount: number }>;
	public getCurrentColumns: () => string[];
	public columnsChange$: Observable<{
		newColumns: string[];
		oldColumns: string[];
	}>;
	public currentItems: () => T[];
	public detectChanges: () => void;
	public switchRealm: (realm: { id: string; name: string } | null) => void;
}
