import { NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getEnvironment } from 'src/lib/environment/environment';
import { convertToInt } from 'src/lib/utilities/convert';
import { NgDompurifyPipe } from '../../../../thirdparty/ng-dompurify/ng-dompurify.pipe';

@Component({
	selector: 'ae-profile-drupal',
	templateUrl: './profile-drupal.component.html',
	styleUrls: ['./profile-drupal.component.scss'],
	standalone: true,
	imports: [NgDompurifyPipe, NgStyle],
})
export class ProfileDrupalComponent implements OnInit {
	public iframeSrc: string;
	public loadingImageUrl = `${getEnvironment().assets}/images/ga_loader.gif`;
	private currentStudentId;
	constructor(private route: ActivatedRoute) {}

	ngOnInit() {
		this.route.params.subscribe((p) => {
			this.currentStudentId = convertToInt(p.userId);

			this.iframeSrc = `/user/${this.currentStudentId}/edit?main_content_only=1`;
		});
	}
}
