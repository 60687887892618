import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize, switchMap, tap } from 'rxjs/operators';
import spacetime from 'spacetime';
import { meetupTitles } from 'src/lib/constants/meetup-data';
import { MeetupManagementListItemModel } from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { MeetupManagementService } from 'src/lib/services/api/organizations/meetups/meetup-management.service';
import { StudentMeetupStoreService } from 'src/lib/services/stores/students/meetup/student-meetup-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { SpaceFormatPipe } from '../../../../../pipes/spacetime/space-format.pipe';
import { SpaceMapPipe } from '../../../../../pipes/spacetime/space-map.pipe';
import { WaitSpinnerComponent } from '../../../../../templates/global/wait-spinner/wait-spinner.component';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';
import { MeetupRsvpRecurringModalService } from '../rsvp-recurring-modal/meetup-rsvp-recurring-modal.service';

@Component({
	selector: 'ae-meetup-reschedule-modal',
	templateUrl: './meetup-reschedule-modal.component.html',
	styleUrls: ['./meetup-reschedule-modal.component.scss'],
	standalone: true,
	imports: [
		DatePipe,
		SpaceFormatPipe,
		SpaceMapPipe,
		SpinWhileDirective,
		WaitSpinnerComponent,
	],
})
export class MeetupRescheduleModalComponent {
	@Input() meetup: MeetupManagementListItemModel;

	public meetupTitles = meetupTitles;

	public saving = false;
	public loading = true;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private toastr: ToastrService,
		private userStoreService: UserStoreService,
		private meetupManagementService: MeetupManagementService,
		private studentMeetupStoreService: StudentMeetupStoreService,
		private meetupRsvpRecurringModalService: MeetupRsvpRecurringModalService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		meetup: MeetupManagementListItemModel;
	}): void => {
		// SET DATA
		this.meetup = data.meetup;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.loading = false;
	};

	public save = () => {
		this.saving = true;
		//call view attendance to get recurring information
		this.meetupManagementService
			.viewAttendance(this.meetup.id)
			.pipe(
				switchMap((meetupAttendance) => {
					if (
						meetupAttendance.recurring_plan &&
						meetupAttendance.recurring_plan.future_meetup_start_id !==
							meetupAttendance.recurring_plan.scheduled_meetup_id
					) {
						return this.meetupRsvpRecurringModalService.openModal$(
							this.meetup.id,
							this.meetup.start_date.getTime(),
							this.meetup.end_date.getTime(),
							true,
						);
					} else {
						return this.meetupManagementService
							.removeAttending(this.meetup.id)
							.pipe(finalize(() => (this.saving = false)));
					}
				}),
			)
			.pipe(
				tap((val) => {
					if (val) {
						this.studentMeetupStoreService.refreshMeetups(
							this.userStoreService.currentUserUid,
							spacetime(this.meetup.start_date).month(),
							spacetime(this.meetup.start_date).year(),
						);
					}
				}),
			)
			.subscribe({
				next: () => {
					this.close(true);
				},
				error: (errors) => {
					this.toastr.error(mergeStrings(errors), 'Reschedule Attending');
				},
			});
	};

	protected close = (val?: boolean) => {
		this.activeModal.close(val);
	};
}
