module.exports = updatePlugin;
function updatePlugin() {
  return {
    update: update
  };
  function update(_, key, optDefaultVal, updateFn) {
    if (arguments.length == 3) {
      updateFn = optDefaultVal;
      optDefaultVal = undefined;
    }
    var val = this.get(key, optDefaultVal);
    var retVal = updateFn(val);
    this.set(key, retVal != undefined ? retVal : val);
  }
}