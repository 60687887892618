import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import spacetime from 'spacetime';
import { ModalMoverComponent } from '../../../../global/modal-mover/modal-mover.component';
import { GroupValidationDisplayComponent } from '../../../../layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from '../../../../layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from '../../../../layout/group-validation/validation-error.directive';
import { PaginationTableColumnDirective } from '../../children/pagination-table-column.directive';
import { IPaginationTable } from '../../core/pagination-table.interface';
import { PaginationTableSavedVisibleColumnConfiguration } from '../../types/ipagination-table-user-preferences';
import { PaginationTableExportConfig } from './pagination-table-exporter/pagination-table-export-config';
import { PaginationTableExporterComponent } from './pagination-table-exporter/pagination-table-exporter.component';

interface PaginationTableExportModalForm {
	columns: FormArray<FormControl<boolean>>;
	allItems: FormControl<boolean>;
	startIndex: FormControl<number>;
	endIndex: FormControl<number>;
}

@Component({
	selector: 'ae-pagination-table-export-modal',
	templateUrl: './pagination-table-export-modal.component.html',
	styleUrls: ['./pagination-table-export-modal.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		ModalMoverComponent,
		NgClass,
		NgStyle,
		NgTemplateOutlet,
		PaginationTableExporterComponent,
		ReactiveFormsModule,
		ValidationErrorDirective,
	],
})
export class PaginationTableExportModalComponent<T> implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() pgTable: IPaginationTable<T>;
	@Input() columnTemplates: PaginationTableColumnDirective<T>[];
	@Input() visibleColumns: PaginationTableSavedVisibleColumnConfiguration;

	@ViewChild('downloadLink') downloadLink: ElementRef<HTMLElement>;

	public exportableColumns: {
		id: string;
		column: PaginationTableColumnDirective<T>;
	}[] = [];
	public configForm: FormGroup<PaginationTableExportModalForm>;

	public exportConfig: PaginationTableExportConfig<T>;
	public exportProgressPercent: number = 0;
	public downloadReady: boolean = false;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private fb: FormBuilder,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		pgTable: IPaginationTable<T>;
		columnTemplates: PaginationTableColumnDirective<T>[];
		visibleColumns: PaginationTableSavedVisibleColumnConfiguration;
	}): void => {
		// SET DATA
		this.pgTable = data.pgTable;
		this.columnTemplates = data.columnTemplates;
		this.visibleColumns = data.visibleColumns;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		const columnsGroup = this.fb.array<boolean>(
			[],
			[
				(group: FormArray<FormControl<boolean>>) => {
					const val = group.value;
					if (!val.find((x) => x)) {
						return { noColumns: true };
					}
					return null;
				},
			],
		);
		this.exportableColumns = this.columnTemplates
			.filter((c) => c.isExportable && !c.disabled)
			.map((c) => {
				return {
					id: c.columnId,
					column: c,
				};
			});

		this.exportableColumns.forEach((c, i) => {
			const ctrl = this.fb.control<boolean>(
				this.visibleColumns[c.id] != null && this.visibleColumns[c.id],
			);
			columnsGroup.insert(i, ctrl);
		});

		this.configForm = this.fb.group<PaginationTableExportModalForm>({
			columns: columnsGroup,
			allItems: new FormControl(true),
			startIndex: new FormControl({ value: 1, disabled: true }, [
				Validators.required,
				Validators.min(1),
			]),
			endIndex: new FormControl(
				{ value: this.pgTable.totalCount, disabled: true },
				[Validators.required, Validators.min(1)],
			),
		});

		this.configForm.controls.allItems.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => {
				if (v) {
					this.configForm.controls.startIndex.disable();
					this.configForm.controls.endIndex.disable();
				} else {
					this.configForm.controls.startIndex.enable();
					this.configForm.controls.endIndex.enable();
				}
			});
	};

	public select = (action: 'all' | 'none') => {
		this.configForm.controls.columns.controls.forEach((c) =>
			c.setValue(action === 'all'),
		);
	};

	public exportProgress = (progress: { complete: number; total: number }) => {
		this.exportProgressPercent = Math.round(
			(progress.complete / progress.total) * 100,
		);
	};

	public exportComplete = (csvContent: string) => {
		this.exportProgressPercent = 100;

		this.downloadLink.nativeElement.className = 'd-block';
		const blob = new Blob([csvContent], { type: 'text/csv' });

		this.downloadLink.nativeElement.setAttribute(
			'href',
			URL.createObjectURL(blob),
		);

		this.downloadLink.nativeElement.setAttribute(
			'download',
			`${this.pgTable.name} ${spacetime
				.now()
				.format('{iso-month}-{date-pad}-{year} {hour}_{minute}{ampm}')}.csv`,
		);
		this.downloadLink.nativeElement.click();

		this.downloadReady = true;
	};

	public start = () => {
		if (this.exportConfig == null) {
			this.exportConfig = {
				pgTable: this.pgTable,
				exportColumns: this.configForm.controls.columns.controls
					.map((c, i) => (c.value ? this.exportableColumns[i].id : null))
					.filter((x) => x != null),
				exportRange: this.configForm.controls.allItems.value
					? [-1, -1]
					: [
							this.configForm.controls.startIndex.value,
							this.configForm.controls.endIndex.value,
						],
			};
		}
	};

	public cancel = () => {
		this.activeModal.dismiss();
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
