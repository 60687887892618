<!-- eslint-disable @angular-eslint/template/no-any -->
<div
	class="online-status-bubble-main"
	[ngClass]="{ 'd-none': linkIds == null || $any(linkIds).length === 0 }"
>
	@switch (currentStatus()) {
		@case (OnlineStatus.online) {
			<div class="online-status-online" container="body" ngbTooltip="Online">
				<span class="screen-reader-text">User Online</span>
			</div>
		}
		@case (OnlineStatus.away) {
			<div class="online-status-away" container="body" ngbTooltip="Away">
				<div class="online-status-away-inner"></div>
				<span class="screen-reader-text">User Away</span>
			</div>
		}
		@case (OnlineStatus.offline) {
			<div class="online-status-offline" container="body" ngbTooltip="Offline">
				<span class="screen-reader-text">User Offline</span>
				<div class="online-status-offline-inner"></div>
			</div>
		}
		@default {
			<div
				class="online-status-unknown"
				container="body"
				ngbTooltip="Unknown Status {{ currentStatus() }}"
			>
				<span class="screen-reader-text"
					>Unknown Status {{ currentStatus() }}</span
				>
			</div>
		}
	}
</div>
