import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { UserAddressModel } from '../../utility/utility-models/address.model';

export class StateTestEventModel {
	address: UserAddressModel;
	@Transform(dateTransformer)
	end_time: Date;
	@Transform(dateTransformer)
	start_time: Date;
	student_notes: string;
	test_id: number;
	test_instance_id: number;
	test_instance_name: string;
	test_name: string;
	planned_attendance_count: number;
	program_count?: number;
	programs?: number[];
	is_indeterminate_time: boolean;
	timezone: string;
	active: boolean;
	event_active: boolean;
}

export function StateTestEventModel_ClassTransform_Type() {
	return StateTestEventModel;
}

export class StateTestEventListModel {
	@Type(StateTestEventModel_ClassTransform_Type)
	state_tests: StateTestEventModel[];
	count: number;
	skip: number;
	take: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface StateTestEventPagingArgument
	extends PagingArgument<StateTestEventSearchArgument> {}

export interface StateTestEventSearchArgument {
	test_name: PagingSearchFieldArgument;
	test_instance_name: PagingSearchFieldArgument;
	start_time: PagingSearchFieldArgument;
	end_time: PagingSearchFieldArgument;
	address_zip: PagingSearchFieldArgument;
	planned_attendance_count: PagingSearchFieldArgument;
	program_count: PagingSearchFieldArgument;
	programs?: PagingSearchFieldArgument;
	date?: PagingSearchFieldArgument;
	event_active?: { value: boolean };
}
