import { NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { AsyncSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { GabbyNotificationService } from '../gabby-notification.service';

export interface GabbyNotificationVolumeComponentForm {
	volume: number;
}

@Component({
	selector: 'ae-gabby-notification-volume',
	templateUrl: './gabby-notification-volume.component.html',
	styleUrls: ['./gabby-notification-volume.component.scss'],
	standalone: true,
	imports: [FormsModule, NgClass, ReactiveFormsModule],
})
export class GabbyNotificationVolumeComponent
	implements OnInit, OnChanges, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() id: any = null;

	public form: FormGroup<
		FormControlWrapper<GabbyNotificationVolumeComponentForm>
	>;

	constructor(
		private ele: ElementRef<HTMLElement>,
		private cdr: ChangeDetectorRef,
		private formBuilder: FormBuilder,
		private gabbyNotificationService: GabbyNotificationService,
	) {}

	ngOnInit() {
		this.form = this.formBuilder.group<
			FormControlWrapper<GabbyNotificationVolumeComponentForm>
		>({
			volume: new FormControl(this.gabbyNotificationService.notificationVolume),
		});

		// Handle value changes
		this.form.controls.volume.valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((x) => {
				this.gabbyNotificationService.notificationVolume = x;
				this.gabbyNotificationService.playNotificationAudio();
				this.cdr.markForCheck();
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.id) {
			this.ele.nativeElement.removeAttribute('id');
		}
	}

	public getVolumeIcon = (): string => {
		const v = this.form.controls.volume.value;

		if (v >= 75) return 'fa-volume-up';
		else if (v >= 50) return 'fa-volume';
		else if (v > 0) return 'fa-volume-down';
		else return 'fa-volume-slash';
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
