import { FormGroup } from '@angular/forms';
import {
	asapScheduler,
	AsyncSubject,
	BehaviorSubject,
	combineLatest,
	firstValueFrom,
	Observable,
	race,
	scheduled,
	Subject,
	Subscription,
} from 'rxjs';
import { filter, last, map, mergeAll, takeUntil, tap } from 'rxjs/operators';
import { Disposable } from 'src/lib/types/disposable.def';
import { PaginationTableResultSet } from 'src/lib/types/pagination-table-result-set.def';
import { isString } from 'src/lib/utilities/compare';
import { firstBy } from 'thenby';
import {
	GetAllKeysFunction,
	PaginationFunction,
} from './types/pagination-function';

export class PaginationHelper<TRow> implements Disposable {
	private _unsubscribe$ = new AsyncSubject<null>();

	private _instantSourceSubject = new AsyncSubject<TRow[]>();
	private _sourceSubject = new BehaviorSubject<TRow[]>(undefined);
	private _sourceSubjectDone: boolean = true;
	private _previousSourceStreamSubscription: Subscription;

	/** Custom filters that will run every time */
	public customFilters?: CustomFilterFunction<TRow>[];
	/** Custom sorters that will run if the selected columnId is in the Map */
	public customSorters = new Map<string, CustomSortFunction<TRow>>();
	/** Emits when a new source stream is set */
	public newSourceStreamSet = new Subject<null>();

	private filterMap: FilterMap<TRow, any>;

	public static CreateSimple<T>(
		...sourceStreams: Observable<T[]>[]
	): PaginationHelper<T> {
		return new PaginationHelper<T>(null, ...sourceStreams);
	}

	public static CreateFiltering<T, K>(
		filterMap: FilterMap<T, K>,
		...sourceStreams: Observable<T[]>[]
	): PaginationHelper<T> {
		return new PaginationHelper<T>(filterMap, ...sourceStreams);
	}

	private constructor(
		filterMap: FilterMap<TRow, any>,
		...sourceStreams: Observable<TRow[]>[]
	) {
		this.filterMap = filterMap;
		if (sourceStreams?.length > 0) {
			this.setSourceStream(...sourceStreams);
		}
	}

	public getSource$ = (): Observable<TRow[]> => {
		return race([
			this._instantSourceSubject.asObservable(),
			this._sourceSubject.asObservable().pipe(filter((x) => x !== undefined)),
		]);
	};

	public setSourceStream(
		...newStreams$: Observable<TRow[]>[]
	): Promise<TRow[]> {
		return firstValueFrom(this.setSourceStream$(...newStreams$));
	}

	public setSourceStream$(
		...newStreams$: Observable<TRow[]>[]
	): Observable<TRow[]> {
		if (newStreams$?.length === 0) {
			throw new Error('PaginationHelper: got no SourceStreams');
		}

		if (this._previousSourceStreamSubscription != null) {
			this._previousSourceStreamSubscription.unsubscribe();
		}

		if (this._sourceSubjectDone) {
			this._sourceSubject = new BehaviorSubject<TRow[]>(undefined);
			this._instantSourceSubject = new AsyncSubject<TRow[]>();
			this._sourceSubjectDone = false;

			this._sourceSubject.pipe(last()).subscribe((x) => {
				this._instantSourceSubject.next(x);
				this._instantSourceSubject.complete();
			});
		}

		const results: TRow[] = [];
		this._previousSourceStreamSubscription = scheduled(
			newStreams$,
			asapScheduler,
		)
			.pipe(mergeAll(2), takeUntil(this._unsubscribe$))
			.subscribe({
				next: (x) => {
					results.splice(results.length, 0, ...x);
					this._sourceSubject.next(results);
				},
				error: (err) => {
					this._sourceSubjectDone = true;
					this._sourceSubject.error(err);
				},
				complete: () => {
					this._sourceSubjectDone = true;
					this._sourceSubject.complete();
				},
			});

		this.newSourceStreamSet.next(null);

		return this._instantSourceSubject.asObservable();
	}

	public setSourceStreamSilent$(
		...newStreams$: Observable<TRow[]>[]
	): Observable<TRow[]> {
		if (newStreams$?.length === 0) {
			throw new Error('PaginationHelper: got no SourceStreams');
		}

		const silentDoneSubject = new AsyncSubject<TRow[]>();
		const results: TRow[] = [];
		let cancelledByNewStream = false;

		combineLatest(newStreams$)
			.pipe(
				mergeAll(2),
				takeUntil(
					race(
						this._unsubscribe$,
						this.newSourceStreamSet.pipe(
							tap(() => {
								cancelledByNewStream = true;
							}),
						),
					),
				),
			)
			.subscribe({
				next: (x) => {
					results.splice(results.length, 0, ...x);
				},
				error: (err) => {
					silentDoneSubject.error(err);
				},
				complete: () => {
					if (!cancelledByNewStream) {
						this.setSourceStream(silentDoneSubject.asObservable());
					}

					silentDoneSubject.next(results);
					silentDoneSubject.complete();
				},
			});

		return silentDoneSubject.asObservable();
	}

	/** Builds the getPage function for use in PaginationTableConfig.getPage */
	public buildGetPage = (): PaginationFunction<TRow> => {
		return (
			pageNumber: number,
			itemsPerPage: number,
			sortKey: string,
			sortDescending: boolean,
			searchForm: FormGroup,
		): Observable<PaginationTableResultSet<TRow>> => {
			return this.getSource$().pipe(
				map((x) => {
					let filteredItems = x;
					if (searchForm != null) {
						filteredItems = this.filterItems(x, searchForm);
					}

					let sortedItems: TRow[];

					if (
						this.customSorters != null &&
						this.customSorters.has(sortKey) &&
						this.customSorters.get(sortKey) != null
					) {
						sortedItems = (
							this.customSorters.get(sortKey) as CustomSortFunction<TRow>
						)(filteredItems, sortDescending);
					} else {
						sortedItems = filteredItems.sort(
							firstBy((y) => y[sortKey] == null || y[sortKey] === '').thenBy(
								(y) => y[sortKey],
								{
									ignoreCase: true,
									direction: sortDescending ? -1 : 1,
								},
							),
						) as TRow[];
					}

					return {
						items: sortedItems.slice(
							(pageNumber - 1) * itemsPerPage,
							itemsPerPage + (pageNumber - 1) * itemsPerPage,
						),
						count: filteredItems.length,
					} as PaginationTableResultSet<TRow>;
				}),
			);
		};
	};

	/** Builds the getAllKeys function for use in PaginationTableConfig.getAllKeys */
	public buildGetAllKeys = (
		selectionKeyProperty: string,
	): GetAllKeysFunction => {
		if (!isString(selectionKeyProperty)) {
			throw new Error('selectionKeyProperty is not a string');
		}

		return (searchForm?: FormGroup): Observable<any[]> => {
			return this.getSource$().pipe(
				last(),
				map((x) => {
					if (searchForm) {
						return this.filterItems(x, searchForm);
					} else return x;
				}),
				map((x) => x.map((y) => y[selectionKeyProperty])),
			);
		};
	};

	private filterItems = (items: TRow[], searchForm: FormGroup): TRow[] => {
		const checkFilters = (
			item: TRow,
			filterMap: FilterMap<TRow, any>,
			innerValues: any,
		) => {
			for (const key in filterMap) {
				if (filterMap.hasOwnProperty(key)) {
					if (!filterMap[key](item, innerValues[key])) {
						return false;
					}
				}
			}

			if (this.customFilters != null) {
				for (const f of this.customFilters) {
					if (!f(item, searchForm)) return false;
				}
			}

			return true;
		};

		return items.filter((item) =>
			checkFilters(item, this.filterMap, searchForm.value),
		);
	};

	public dispose = (): void => {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
		this.newSourceStreamSet.complete();
	};
}

export type CustomSortFunction<TRow> = (
	items: TRow[],
	sortDescending: boolean,
) => TRow[];
export type CustomFilterFunction<TRow> = (
	item: TRow,
	searchForm: FormGroup,
) => boolean;
export type FilterFunction<TRow, K> = (item: TRow, filter: K) => boolean;
export type FilterMap<TRow, TForm> = {
	[Key in keyof TForm]?: FilterFunction<TRow, TForm[Key]>;
} & Record<string, FilterFunction<TRow, any>>;
