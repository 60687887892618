import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';

export class BackgroundCheckCreatedByModel {
	uid: number;
	name: string;
}

export function BackgroundCheckCreatedByModel_ClassTransform_Type() {
	return BackgroundCheckCreatedByModel;
}

export class BackgroundCheckModel {
	id: number;
	status: number;
	status_title: string;
	state: string;
	@Transform(dateTransformer)
	valid_from: Date;
	@Transform(dateTransformer)
	expires_on: Date;
	notes: string;
	@Transform(dateTransformer)
	created_on: Date;
	@Type(BackgroundCheckCreatedByModel_ClassTransform_Type)
	created_by: BackgroundCheckCreatedByModel;
}

export function BackgroundCheckModel_ClassTransform_Type() {
	return BackgroundCheckModel;
}

export class BackgroundCheckTrackerModel {
	uid: number;
	name: string;
	status: number;
	@Type(BackgroundCheckModel_ClassTransform_Type)
	background_check: BackgroundCheckModel;
}

export function BackgroundCheckTrackerModel_ClassTransform_Type() {
	return BackgroundCheckTrackerModel;
}

export class BackgroundCheckTrackerResponseModel {
	success: boolean;
	count: number;
	@Type(BackgroundCheckTrackerModel_ClassTransform_Type)
	results: BackgroundCheckTrackerModel[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface BackgroundCheckTrackerPagingArgument
	extends PagingArgument<BackgroundCheckSearchArgument> {}

export interface BackgroundCheckSearchArgument {
	status: PagingSearchFieldArgument;
	staff_background_check_valid_from: PagingSearchFieldArgument;
	staff_background_check_expires_on: PagingSearchFieldArgument;
	staff_background_check_state: PagingSearchFieldArgument;
	staff_background_check_status: PagingSearchFieldArgument;
}

export class BackgroundCheckArgument {
	valid_from: Date;
	expires_on?: Date;
	state: string;
	status: number;
	notes?: string;
}
