import {
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { defaultAllowedFileTypes } from 'src/lib/utilities/file';
import { createFileSize, createValidFileExtension } from './validators';

import { NgClass } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { noop } from 'src/lib/utilities/noop';
import { SpinWhileDirective } from '../../layout/spin-while/spin-while.directive';

@Component({
	selector: 'ae-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => FileUploaderComponent),
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => FileUploaderComponent),
		},
	],
	standalone: true,
	imports: [NgClass, SpinWhileDirective],
})
export class FileUploaderComponent
	implements ControlValueAccessor, OnInit, OnChanges
{
	@HostBinding('class.custom-form-control') customFormControl: boolean = true;

	@Input() extensionsAllowed: string[] = defaultAllowedFileTypes;
	@Input() maxSizeBytes: number;
	@Input() previewImageClass: string;

	public disabled: boolean = false;
	public imageFile: boolean = false;
	public file: File;
	public resizing = false;

	private _validateFns: ((
		control: FormControl,
	) => Record<string, unknown> | null)[];
	private imageFormat: string[] = ['jpeg', 'jpg', 'png', 'gif'];
	private checkIfImageFormat;

	@ViewChild('previewImage')
	private previewImg: ElementRef;

	@ViewChild('wrapper', { static: true })
	private wrapper: ElementRef;

	private _changeFunction: (value: File) => void = noop;
	private _touchedFunction: () => void = noop;

	constructor(
		private ele: ElementRef<Element>,
		private rndr: Renderer2,
		private toastr: ToastrService,
	) {}

	public writeValue(obj: any): void {
		this.setInternalValue(obj);
	}

	private setInternalValue(file: File): void {
		this.file = file;
		this.imageFile = false;
		if (this.file && this.checkIfImageFormat({ value: this.file }) == null) {
			this.imageFile = true;
			const fr = new FileReader();
			fr.onload = (_e) => {
				if (this.previewImg) {
					this.previewImg.nativeElement.src = fr.result;
				}
			};
			fr.readAsDataURL(this.file);
		}
	}

	private notify = () => {
		this._touchedFunction();
		this._changeFunction(this.file);
	};

	private handleFileUpload = (file: any) => {
		this.file = undefined;

		if (this.checkIfImageFormat({ value: file }) == null) {
			if (this.maxSizeBytes != null && file.size > this.maxSizeBytes) {
				this.toastr.error(
					`File must be less than ${this.maxSizeBytes / 1000000}MB`,
				);
				this.notify();
			} else {
				this.setInternalValue(file);
				this.notify();
			}
		} else {
			this.setInternalValue(file);
			this.notify();
		}
	};

	public fileUploaded = (event: any) => {
		if (event.target.files.length > 0) {
			this.handleFileUpload(event.target.files[0]);
		}
	};

	// referenced file drag and drop from: https://github.com/valor-software/ng2-file-upload/blob/development/src/file-upload/file-drop.directive.ts
	@HostListener('drop', ['$event'])
	public onDrop = (event: DragEvent): void => {
		this.handleFileUpload(event.dataTransfer.files[0]);
	};

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	public registerOnTouched(fn: any): void {
		this._touchedFunction = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (this.disabled) {
			this.rndr.addClass(this.wrapper.nativeElement, 'disabled');
		} else {
			this.rndr.removeClass(this.wrapper.nativeElement, 'disabled');
		}
	}

	public validate = (ctrl: FormControl) => {
		const errors = {};
		this._validateFns?.forEach((fn) => {
			const error = fn(ctrl);
			if (error) {
				const key = Object.keys(error)[0];
				errors[key] = error[key];
			}
		});
		return errors;
	};

	ngOnInit() {
		this.checkIfImageFormat = createValidFileExtension(this.imageFormat);

		this._validateFns = [];
		if (this.maxSizeBytes) {
			this._validateFns.push(createFileSize(this.maxSizeBytes));
		}
		this._validateFns.push(createValidFileExtension(this.extensionsAllowed));

		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.attributeName === 'class') {
					if (
						this.ele.nativeElement.classList.contains('group-is-invalid') ||
						this.ele.nativeElement.classList.contains('is-invalid') ||
						this.ele.nativeElement.classList.contains('ng-invalid')
					) {
						this.rndr.addClass(this.wrapper.nativeElement, 'is-invalid');
					} else {
						this.rndr.removeClass(this.wrapper.nativeElement, 'is-invalid');
					}
				}
			});
		});
		observer.observe(this.ele.nativeElement, {
			attributes: true,
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this._validateFns = [];

		if (changes.maxSizeKB && this.maxSizeBytes) {
			this._validateFns.push(createFileSize(this.maxSizeBytes));
		}

		this._validateFns.push(createValidFileExtension(this.extensionsAllowed));

		this._changeFunction(this.file);
	}
}
