import { NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	NgbDropdown,
	NgbDropdownMenu,
	NgbDropdownToggle,
	NgbModal,
	NgbModalOptions,
	NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
	AsyncSubject,
	BehaviorSubject,
	Observable,
	Subject,
	Subscription,
	animationFrameScheduler,
	combineLatest,
	firstValueFrom,
	merge,
	of,
	scheduled,
	timer,
} from 'rxjs';
import {
	debounce,
	debounceTime,
	delay,
	distinctUntilChanged,
	exhaustMap,
	filter,
	map,
	pairwise,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import spacetime from 'spacetime';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { UserSettingsStoreService } from 'src/lib/services/stores/users/settings/user-settings-store.service';
import { ExpectedError } from 'src/lib/types/expected-error';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { PaginationTableResultSet } from 'src/lib/types/pagination-table-result-set.def';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import {
	hasValue,
	isArray,
	isEquivalentArray,
	isNonEmptyString,
	isNullOrEmptyString,
	isNumber,
} from 'src/lib/utilities/compare';
import { copyToAbstractControl } from 'src/lib/utilities/forms';
import { noop } from 'src/lib/utilities/noop';
import { generateGuid } from 'src/lib/utilities/random';
import { firstBy } from 'thenby';
import { Key } from 'ts-key-enum';
import { AnchorLinkDirective } from '../../../global/anchor-link/anchor-link.directive';
import { FocusTransferDirective } from '../../../global/focus-transfer/focus-transfer.directive';
import { PaginationTableColumnDirective } from '../children/pagination-table-column.directive';
import { PaginationTableControlsCustomDirective } from '../children/pagination-table-controls-custom.directive';
import { PaginationTableFiltersCustomDirective } from '../children/pagination-table-filters-custom.directive';
import { PaginationTableFooterCustomDirective } from '../children/pagination-table-footer-custom.directive';
import { PaginationTableFormModalCustomDirective } from '../children/pagination-table-form-modal-custom.directive';
import { PaginationTableRowActionsColumnDirective } from '../children/pagination-table-row-actions-column.directive';
import { PaginationTableStateErrorDirective } from '../children/pagination-table-state-error.directive';
import { PaginationTableStateLoadingDirective } from '../children/pagination-table-state-loading.directive';
import { PaginationTableStateNoItemsDirective } from '../children/pagination-table-state-noitems.directive';
import { PaginationTableConfigurationModalComponent } from '../modals/pagination-table-configuration-modal/pagination-table-configuration-modal.component';
import { PaginationTableExportModalComponent } from '../modals/pagination-table-export-modal/pagination-table-export-modal.component';
import { PaginationTableSearchModalComponent } from '../modals/pagination-table-search-modal/pagination-table-search-modal.component';
import { PaginationTableSearchSaveModalComponent } from '../modals/pagination-table-search-save-modal/pagination-table-search-save-modal.component';
import { PaginationTableActionDefinition } from '../pagination-table-action-definition';
import { PaginationTableApi } from '../pagination-table-api';
import {
	IPaginationTable,
	PaginationTableConfig,
	paginationTableMaxAllowedPageSize,
} from '../pagination-table-config';
import {
	PaginationTableSavedConfiguration,
	PaginationTableSavedSearch,
	PaginationTableSavedVisibleColumnConfiguration,
	PaginationTableStyleRule,
	PaginationTableUserPreferences,
	PaginationTableUserSortPreferences,
} from '../types/ipagination-table-user-preferences';
import { PaginationTableStyleRuleComparison } from '../types/pagination-table-style-rule-comparison';
import {
	InternalPaginationTable,
	PGTableStaticItem,
	PaginationTableSavedConfigurationForm,
	PaginationTableUserPreferencesForm,
	TableState,
	protectedPaginationTableConfigurationDefaultName,
} from './internal-pagination-table.interface';
import {
	PaginationTableMarginSizeConst,
	PaginationTableStyleRuleComparisonConst,
	minimumColumnWidth,
	warningColumnWidth,
} from './pagination-table-configuration.data';

interface CalculatedColumnStyleRules<T> {
	rules: PaginationTableStyleRule[];
	parseNeeded: boolean;
	rowMap: Map<T, string>;
}

interface ColumnStyleRenderRequest<T> {
	staticItem: PGTableStaticItem<T>;
	col: PaginationTableColumnDirective<T>;
	element: HTMLElement;
	colCompute: CalculatedColumnStyleRules<T>;
}

@Component({
	selector: 'ae-pgt',
	templateUrl: './pagination-table.component.html',
	styleUrls: ['./pagination-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AnchorLinkDirective,
		FocusTransferDirective,
		FormsModule,
		NgbDropdown,
		NgbDropdownMenu,
		NgbDropdownToggle,
		NgbTooltip,
		NgClass,
		NgTemplateOutlet,
		ReactiveFormsModule,
	],
})
export class PaginationTableComponent<
		T,
		F extends {
			[K in keyof F]: AbstractControl<any>;
		} = any,
	>
	implements
		OnInit,
		AfterContentInit,
		AfterViewInit,
		OnDestroy,
		InternalPaginationTable<T>,
		IPaginationTable<T, F>
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() tableConfig: PaginationTableConfig<T, F>;
	@Input() class: string = 'table-striped';
	@Input() name: string = 'Table';
	@Input() minWidth: string;

	@ContentChildren(PaginationTableColumnDirective, { descendants: true })
	public columnTemplates: QueryList<PaginationTableColumnDirective<T, F>>;

	@ContentChild(PaginationTableRowActionsColumnDirective)
	public rowActionsTemplate: PaginationTableRowActionsColumnDirective<T>;

	@ContentChildren(PaginationTableFormModalCustomDirective, {
		descendants: false,
	})
	private customFormTemplate: QueryList<PaginationTableFormModalCustomDirective>;

	@ContentChildren(PaginationTableControlsCustomDirective, {
		descendants: false,
	})
	public customControlsTemplate: QueryList<PaginationTableControlsCustomDirective>;

	@ContentChildren(PaginationTableFiltersCustomDirective, {
		descendants: false,
	})
	public customFiltersTemplate: QueryList<PaginationTableFiltersCustomDirective>;

	@ContentChildren(PaginationTableFooterCustomDirective, {
		descendants: false,
	})
	public customFooterTemplate: QueryList<PaginationTableFooterCustomDirective>;

	@ContentChildren(PaginationTableStateErrorDirective, {
		descendants: false,
	})
	public stateErrorTemplate: QueryList<PaginationTableStateErrorDirective>;
	@ContentChildren(PaginationTableStateLoadingDirective, {
		descendants: false,
	})
	public stateLoadingTemplate: QueryList<PaginationTableStateLoadingDirective>;
	@ContentChildren(PaginationTableStateNoItemsDirective, {
		descendants: false,
	})
	public stateNoItemsTemplate: QueryList<PaginationTableStateNoItemsDirective>;

	@ViewChild('pgTableElement', { static: false })
	pgTableElement: ElementRef<HTMLElement>;

	@ViewChild('configTableBtnTooltip', { static: false })
	public configTableBtnTooltip: NgbTooltip;

	@ViewChildren('tableScrollContainer') scrollContainers: QueryList<
		ElementRef<HTMLElement>
	>;

	// Enums
	public TableState = TableState;
	public PaginationTableMarginSize = PaginationTableMarginSizeConst;
	public ColumnStyleRuleComparison = PaginationTableStyleRuleComparisonConst;

	private tableApi: PaginationTableApi<T>;
	public redraw$ = new Subject<null | true>();
	public redrawSlow$ = new Subject<null>();
	private columnStyleRenderRequest$ = new Subject<
		ColumnStyleRenderRequest<T>
	>();

	// Data
	public nextSubscription$: Subscription;
	public getAllKeysSubscription$: Subscription;
	public currentItems: T[];
	public totalCount: number;
	public totalPages: number;
	public viewingLow: number;
	public viewingHigh: number;

	// Page State
	public currentPage: number;
	public selectedPageSize: number;
	public sortKey: string;
	public sortDescending: boolean;
	private seekCount: number = 0;

	// Rendering
	protected getPageActive: boolean;
	protected hasFirstResult: boolean;
	public currentState: TableState;
	protected staticColumns: {
		col: PaginationTableColumnDirective<T>;
		visible: boolean;
	}[] = [];
	protected staticItems: PGTableStaticItem<T>[];
	protected visibleColumnCount: number;
	protected notification: boolean;
	protected lastError: any;
	protected undersizedColumnsNotification: boolean = false;
	private undersizedColumnsNotificationSuppressed: boolean = false;
	private columns_TotalStarWidth: number;
	private columns_CalculatedStyleRules = new Map<
		string,
		CalculatedColumnStyleRules<T>
	>();
	private tc_Actions_CalculatedTooltips = new Map<
		PaginationTableActionDefinition,
		string | null
	>();
	private tc_Actions_RowActionCacheTimestamp = new Map<HTMLElement, number>();
	private tc_Build_RowActions_ToggleContainer_Handled = new Set<HTMLElement>();

	// Selection
	public selectedItems: any[];
	private selection_selectionChanged$ = new Subject<{
		newCount: number;
		oldCount: number;
	}>();
	protected allKeysSelected: boolean;
	private selection_lastIndex: number = -1;
	private keyheld_Shift: boolean = false;

	// Events
	private afterContentInit$ = new AsyncSubject<null>();
	private tableReady$ = new AsyncSubject<null>();
	private currentState$ = new BehaviorSubject<TableState>(TableState.loading);
	private columns_visibleChanged$ = new BehaviorSubject<string[]>([]);

	// Configuration
	private activeSaveId$ = new BehaviorSubject<string>(null);
	public activeRealm: string | null;
	public activeRealmName: string | null;
	public userPreferences: PaginationTableUserPreferences;
	public userPrefForm: FormGroup<PaginationTableUserPreferencesForm>;
	public userPrefFormIgnoreChangesCount: number = 0; // Angular doesn't let you block events when changing FormArrays https://github.com/angular/angular/issues/20439#issuecomment-456267166

	// Configuration Modal
	private modal_ConfigurationModalOpen$ = new BehaviorSubject<boolean>(false);

	constructor(
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private settingsStore: UserSettingsStoreService,
		private localStore: LocalStoreService,
		private toastrService: ToastrService,
		private fb: FormBuilder,
		private ngZone: NgZone,
	) {}

	/*
	 *
	 * Table Initialization
	 *
	 */
	ngOnInit(): void {
		if (this.tableConfig == null) {
			throw new Error('ae-pgt is missing required input tableConfig');
		}

		this.setupUserPreferences();

		this.currentState$.pipe(takeUntil(this._unsubscribe$)).subscribe((x) => {
			this.currentState = x;
			this.redraw$.next(null);
		});

		this.totalCount = 0;
		this.totalPages = 0;
		this.viewingLow = 0;
		this.viewingHigh = 0;
		this.selectedItems = [];

		// Select the first page or MaxInt if we aren't going to page
		this.selectedPageSize =
			this.tableConfig.pagination.sizes === 'infinite'
				? Number.MAX_SAFE_INTEGER
				: this.tableConfig.pagination.sizes[0];

		this.staticItems = [];
		this.staticColumns = [];

		this.setTableApi();
		this.setupExtraEvents();
		this.loadUserPreferences(
			this.tableConfig.initRealm?.id,
			this.tableConfig.initRealm?.name,
		);
		this.watchColumnStyleRenders();
	}

	ngAfterViewInit(): void {
		// Setup header/body scroll sync
		const unsubFuncs = [];
		let blockNext = false;

		const unsubAllScrollHandlers = () => {
			unsubFuncs.forEach((x) => x());
			unsubFuncs.length = 0;
		};

		const resubAllScrollHandlers = () => {
			this.scrollContainers.forEach((sc) => {
				const scrollHandle = (_e: Event) => {
					if (blockNext) {
						blockNext = false;
						return;
					}

					this.scrollContainers.forEach((osc) => {
						if (osc !== sc) {
							blockNext = true;
							osc.nativeElement.scrollLeft = sc.nativeElement.scrollLeft;
						}
					});
				};

				sc.nativeElement.addEventListener('scroll', scrollHandle);

				unsubFuncs.push(() => {
					sc.nativeElement.removeEventListener('scroll', scrollHandle);
				});
			});
		};

		this.scrollContainers.changes
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: () => {
					unsubAllScrollHandlers();
					resubAllScrollHandlers();
				},
				complete: () => {
					unsubAllScrollHandlers();
				},
			});

		resubAllScrollHandlers();

		// Watch for shift key
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === Key.Shift) {
				this.keyheld_Shift = true;
			}
		};

		const handleKeyUp = (event: KeyboardEvent) => {
			if (event.key === Key.Shift) {
				this.keyheld_Shift = false;
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('keyup', handleKeyUp);
		this._unsubscribe$.subscribe(() => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('keyup', handleKeyUp);
		});
	}

	ngAfterContentInit(): void {
		this.columnTemplates.forEach((x) => {
			x.onChange$.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
				this.columns_RecalculateColumns();
				this.redraw$.next(null);
			});
		});

		// Setup column sorting and visibility
		merge(this.columnTemplates.changes, of(null))
			.pipe(
				switchMap(() =>
					merge(
						this.userPrefForm.controls.activeConfiguration.valueChanges,
						of(this.userPrefForm.controls.activeConfiguration.value),
					),
				),
				debounceTime(10),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.staticColumns = this.columnTemplates.map((x) => {
					return {
						col: x,
						visible: false,
					};
				});

				if (
					Object.keys(
						this.userPreferences.activeConfiguration.visibleColumns ?? {},
					).length === 0
				) {
					this.tc_Configuration_ResetColumnVisibility();
				}

				this.columns_RecalculateColumns();
				this.redraw$.next(null);
			});

		// Get first results
		let initSortKey: string = null;
		let initSortDescending: boolean = null;
		if (this.tableConfig.pagination.initialSort) {
			initSortKey = this.tableConfig.pagination.initialSort.columnId;
			initSortDescending = this.tableConfig.pagination.initialSort.descending;
		} else {
			const col = this.getColumns().find((x) => x.sortable);
			if (col != null) {
				initSortKey = col.columnId;
			}
		}

		if (initSortKey != null) {
			this.setSort(initSortKey, initSortDescending);
		} else this.seek(0);

		if (this.tc_Search_Enabled()) {
			let stashedSeekCount: number;
			this.tableConfig.search.form.valueChanges
				.pipe(
					tap(() => {
						stashedSeekCount = this.seekCount;
					}),
					debounceTime(this.tableConfig.search.debounceTime),
					filter(() => stashedSeekCount === this.seekCount),
					takeUntil(this._unsubscribe$),
				)
				.subscribe(() => {
					this.allKeysSelected = false;

					this.rescale();
				});
		}

		// Check for new columns
		this.tableReady$
			.pipe(
				filter(() => this.tableConfig.controls.columns.interactable),
				map(() => {
					const configsToCheck = [
						this.userPreferences.activeConfiguration?.visibleColumns,
						...(this.userPreferences.savedConfigurations?.map(
							(sc) => sc.visibleColumns,
						) ?? []),
						this.userPreferences.notifiedColumns?.reduce((acc, v) => {
							acc[v] = true;
							return acc;
						}, {} as PaginationTableSavedVisibleColumnConfiguration),
					].filter((x) => x != null && Object.keys(x).length > 0);

					if (configsToCheck.length === 0) {
						return false;
					}

					return this.getColumns(true)
						.map((c) => c.columnId)
						.some((c) => {
							return configsToCheck.every((config) => config[c] == null);
						});
				}),
				filter((x) => x === true),
				switchMap(() =>
					timer(0, 1000).pipe(
						filter(() => this.configTableBtnTooltip != null),
						take(1),
					),
				),
				switchMap(() => {
					this.configTableBtnTooltip.open();
					this.notification = true;
					return this.modal_ConfigurationModalOpen$;
				}),
				filter((x) => x === true),
				tap(() => {
					this.configTableBtnTooltip.close();
					this.notification = false;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe();

		// Setup the table for rendering
		this.afterContentInit$.next(null);
		this.afterContentInit$.complete();

		const afterRedraw$ = new Subject<null>();
		afterRedraw$
			.pipe(debounceTime(250), takeUntil(this._unsubscribe$))
			.subscribe(() => this.doAfterRedraw());

		let redrawDebounceSubscription: Subscription;
		const rebuildRedrawDebounceSubscription = () => {
			redrawDebounceSubscription = this.redraw$
				.pipe(debounceTime(10), takeUntil(this._unsubscribe$))
				.subscribe(() => {
					this.cdr.detectChanges();
					afterRedraw$.next(null);
				});
		};
		rebuildRedrawDebounceSubscription();
		this.redraw$.pipe(takeUntil(this._unsubscribe$)).subscribe((x) => {
			if (x === true) {
				redrawDebounceSubscription.unsubscribe();
				this.cdr.detectChanges();
				afterRedraw$.next(null);
				rebuildRedrawDebounceSubscription();
			}
		});

		this.redrawSlow$
			.pipe(debounceTime(250), takeUntil(this._unsubscribe$))
			.subscribe(() => {
				this.redraw$.next(null);
			});
	}

	private doAfterRedraw = () => {
		if (this.pgTableElement) {
			this.ngZone.runOutsideAngular(() => {
				const elements =
					this.pgTableElement.nativeElement.querySelectorAll('tbody tr');

				elements.forEach((e: HTMLElement, rowIndex: number) => {
					if (!this.tc_Build_RowActions_ToggleContainer_Handled.has(e)) {
						this.tc_Build_RowActions_ToggleContainer_Handled.add(e);

						e.addEventListener(
							'mouseenter',
							this.tc_Build_RowActions_ToggleContainer(e, true),
						);
						e.addEventListener(
							'mouseleave',
							this.tc_Build_RowActions_ToggleContainer(e, false),
						);

						const contentCells = e.querySelectorAll('[cell-auto-title]');
						contentCells.forEach((cc: HTMLElement) => {
							cc.addEventListener('mouseenter', () => {
								const attr = cc.getAttribute('cell-auto-title');

								if (attr === 'false') {
									// Do nothing
								} else if (attr === 'true') {
									cc.title = cc.innerText || cc.textContent;
								} else {
									const columnId = cc.getAttribute('cell-columnId');
									const columnDefinition = this.columnTemplates.find(
										(ct) => ct.columnId === columnId,
									);

									const row = this.staticItems[rowIndex]?.item;

									if (
										columnDefinition &&
										row &&
										columnDefinition.autoTitle instanceof Function
									) {
										cc.title = columnDefinition.autoTitle(row, columnId);
									}
								}
							});
						});
					}
				});

				if (this.tableConfig.controls.columns.interactable) {
					let undersized = false;

					const headers = this.pgTableElement.nativeElement.querySelectorAll(
						'thead th[pgTableHeader]',
					);
					headers.forEach((e: HTMLElement) => {
						// checkVisibility() isn't available in es2019
						if (e.getClientRects().length > 0) {
							if (e.clientWidth < minimumColumnWidth) {
								this.columns_SetResizedWidth(
									e.getAttribute('pgTableHeader'),
									minimumColumnWidth,
								);
								this.redraw$.next(null);
							}

							if (e.clientWidth < warningColumnWidth) {
								undersized = true;
							}
						}
					});

					if (
						this.undersizedColumnsNotification !== undersized &&
						!this.undersizedColumnsNotificationSuppressed
					) {
						this.undersizedColumnsNotification = undersized;
						this.redraw$.next(null);
					}
				}
			});
		}
	};

	private setTableApi = () => {
		this.tableApi = new PaginationTableApi({
			refresh: ({
				keepPage = false,
				keepSelection = false,
				silent = false,
			}: {
				keepPage?: boolean;
				keepSelection?: boolean;
				silent?: boolean;
			} = {}): Promise<unknown> => {
				// Let events to finish first
				const asyncSubject = new AsyncSubject<null>();

				timer(10)
					.pipe(
						switchMap(() => {
							if (!keepSelection) {
								this.tc_Selection_Reset();
							}

							if (keepPage) {
								return this.seek(this.currentPage).pipe(
									take(1),
									switchMap(() => {
										let retObs$: Observable<any> = of(null);

										if (
											(this.currentItems == null ||
												this.currentItems.length === 0) &&
											this.currentPage > 1
										) {
											retObs$ = retObs$.pipe(
												switchMap(() => {
													return this.rescale(silent);
												}),
											);
										}

										return retObs$;
									}),
								);
							} else {
								return this.rescale(silent);
							}
						}),
						takeUntil(this._unsubscribe$),
					)
					.subscribe({
						complete: () => {
							asyncSubject.next(null);
							asyncSubject.complete();
						},
						error: (err) => {
							asyncSubject.error(err);
						},
					});

				return firstValueFrom(asyncSubject);
			},
			getSelectedKeys: () => {
				if (this.tableConfig.selection.keyProperty == null) {
					throw new Error(
						'TableConfig.selection.keyProperty is null. Cannot use selection',
					);
				}

				return this.selectedItems;
			},
			clearSelectedKeys: () => {
				this.tc_Selection_Reset();
			},
			selectionChange$: this.selection_selectionChanged$.asObservable(),
			getCurrentColumns: () =>
				this.staticColumns.filter((x) => x.visible).map((x) => x.col.columnId),
			columnsChange$: this.columns_visibleChanged$.pipe(
				debounceTime(10),
				debounce(() =>
					this.modal_ConfigurationModalOpen$.pipe(filter((m) => m === false)),
				),
				distinctUntilChanged(isEquivalentArray),
				pairwise(),
				map(([oldColumns, newColumns]) => {
					return { oldColumns, newColumns };
				}),
				takeUntil(this._unsubscribe$),
			),
			currentItems: () => this.currentItems?.slice(),
			detectChanges: () => {
				this.cdr.detectChanges();
			},
			switchRealm: (realm: { id: string; name: string } | null) => {
				this.loadUserPreferences(realm?.id, realm?.name);
			},
		});

		this.tableConfig.notifyTableReady(this.tableApi);
	};

	private setupUserPreferences = () => {
		this.userPrefForm = this.formBuild_UserPreferences_Group();
		this.userPreferences = JSON.parse(JSON.stringify(this.userPrefForm.value));

		// Output the form values to the UI
		this.userPrefForm.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => {
				this.userPreferences = JSON.parse(JSON.stringify(v));
			});

		// Save user preferences
		this.tableReady$
			.pipe(
				switchMap(() => this.activeSaveId$),
				switchMap((saveId) =>
					combineLatest([
						of(saveId),
						this.userPrefForm.valueChanges.pipe(
							filter(() => this.userPrefFormIgnoreChangesCount === 0),
							debounceTime(1000),
						),
					]),
				),
				exhaustMap(([saveId, config]) => {
					if (config != null) {
						return this.settingsStore.saveSetting$(saveId, config);
					} else {
						// Delete the config
						return this.settingsStore.saveSetting$(saveId, null);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe();

		// Copy activeConfiguration to savedConfiguration when it changes
		this.userPrefForm.controls.activeConfiguration.valueChanges
			.pipe(
				filter(
					(v) => v.name !== protectedPaginationTableConfigurationDefaultName,
				),
				filter(() => this.userPrefFormIgnoreChangesCount === 0),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((v) => {
				const config =
					this.userPrefForm.controls.savedConfigurations.controls.find(
						(x) => x.value.configId === v.configId,
					);

				if (config) {
					config.setValue(v as PaginationTableSavedConfiguration);
				}
			});
	};

	private loadUserPreferences = (
		realmId: string | null,
		realmName: string | null,
	) => {
		realmId = realmId ?? null; // Used for null and undefined comparison

		let saveId = `pgtable.${this.tableConfig.tableId}.config.userpref`;
		if (realmId != null) {
			saveId = `${saveId}.realm.${realmId}`;
		}

		if (this.activeRealm === realmId) {
			return;
		}

		combineLatest([
			this.settingsStore.getSetting$<PaginationTableUserPreferences>(saveId),
			this.afterContentInit$,
			this.tableConfig.initReady$,
		])
			.pipe(
				tap(() => {
					const defaultConfig = this.formBuild_UserPreferences_Group();

					this.userPrefFormIgnoreChangesCount++;
					this.userPrefForm.controls.savedSearches.clear();
					this.userPrefForm.controls.savedConfigurations.clear();
					this.userPrefForm.patchValue(defaultConfig.value);
					this.userPrefFormIgnoreChangesCount--;
				}),
				tap(([loadedConfig]) => {
					this.activeRealm = realmId;
					this.activeRealmName = realmName;
					this.activeSaveId$.next(saveId);

					if (loadedConfig == null) {
						this.load_SavedConfiguration(
							this.formBuild_SavedConfiguration_Group()
								.value as PaginationTableSavedConfiguration,
						);
					} else {
						this.userPrefFormIgnoreChangesCount++;

						const setupConfig = (
							configGroup: FormGroup<PaginationTableSavedConfigurationForm>,
							ruleCount: number,
						) => {
							this.userPrefFormIgnoreChangesCount++;
							configGroup.controls.styleRules.clear();
							this.userPrefFormIgnoreChangesCount--;

							for (let i = 0; i < ruleCount; i++) {
								this.load_AddStyleRuleFormGroup(configGroup);
							}
						};

						let activeConfig: PaginationTableSavedConfiguration = null;
						if (this.tableConfig.controls.columns.interactable) {
							activeConfig = loadedConfig.activeConfiguration;
						}

						// Columns are loaded after the first render event
						if (activeConfig) {
							this.load_ColumnVisibility(activeConfig.visibleColumns);
							setupConfig(
								this.userPrefForm.controls.activeConfiguration,
								activeConfig.styleRules ? activeConfig.styleRules.length : 0,
							);
						} else {
							this.load_SavedConfiguration(
								this.formBuild_SavedConfiguration_Group()
									.value as PaginationTableSavedConfiguration,
							);
						}

						if (
							this.tableConfig.controls.columns.interactable &&
							loadedConfig.savedConfigurations
						) {
							// eslint-disable-next-line @typescript-eslint/prefer-for-of
							for (
								let i = 0;
								i < loadedConfig.savedConfigurations.length;
								i++
							) {
								this.userPrefForm.controls.savedConfigurations.push(
									this.formBuild_SavedConfiguration_Control(),
								);
							}
						}

						if (!hasValue(loadedConfig.expandRowActions)) {
							loadedConfig.expandRowActions =
								this.tableConfig.controls.rowActions.default;
						}

						if (
							isArray(this.tableConfig.pagination.sizes) &&
							this.tableConfig.pagination.sizes.indexOf(
								loadedConfig.pageSize,
							) !== -1
						) {
							this.selectedPageSize = loadedConfig.pageSize;

							this.rescale();
						}

						// If that column still exists use the sort key, otherwise don't
						if (
							loadedConfig.sort != null &&
							this.getColumns()
								.filter((c) => c.sortable)
								.find((c) => c.columnId === loadedConfig.sort.key)
						) {
							this.sortKey = loadedConfig.sort.key ?? this.sortKey;
							this.sortDescending =
								loadedConfig.sort.descending ?? this.sortDescending;
						} else {
							delete loadedConfig.sort;
						}

						if (
							this.tableConfig.search.form != null &&
							loadedConfig.savedSearches?.length > 0
						) {
							loadedConfig.savedSearches.forEach((x) => {
								const savedSearchGroup = this.formBuild_SavedSearch_Group();
								savedSearchGroup.patchValue(x);
								this.userPrefForm.controls.savedSearches.push(savedSearchGroup);

								if (x.autoLoad) {
									this.tc_Search_Load(x);
								}
							});
						} else {
							delete loadedConfig.savedSearches;
						}

						if (!this.tableConfig.controls.sticky.interactable) {
							delete loadedConfig.stickyHeader;
						}

						if (!this.tableConfig.controls.margins.interactable) {
							delete loadedConfig.marginSize;
						}

						if (!this.tableConfig.controls.rowActions.interactable) {
							delete loadedConfig.expandRowActions;
						}

						this.userPrefForm.patchValue(loadedConfig);
						this.userPrefFormIgnoreChangesCount--;

						this.redraw$.next(null);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.tableReady$.next(null);
				this.tableReady$.complete();
			});

		this.undersizedColumnsNotificationSuppressed = this.localStore.get(
			`${this.tableConfig.tableId}.undersizedColumnsNotificationSuppressed`,
			TypedStoreType.BOOLEAN,
			false,
		);
	};

	private setupExtraEvents = () => {
		// Scrub the calculated style rule on change
		this.userPrefForm.controls.activeConfiguration.controls.styleRules.valueChanges
			.pipe(
				filter(() => this.userPrefFormIgnoreChangesCount === 0),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.columns_CalculatedStyleRules.clear();
			});
	};

	private watchColumnStyleRenders() {
		const pendingRenders: ColumnStyleRenderRequest<T>[] = [];
		let hasRunRenders = false;

		this.currentState$
			.pipe(
				filter(() => hasRunRenders),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				pendingRenders.length = 0;
			});

		this.columnStyleRenderRequest$
			.pipe(
				tap((x) => {
					pendingRenders.push(x);
				}),
				debounceTime(50),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				hasRunRenders = true; // Needed incase the table gets data rapidly

				const cacheRenders = pendingRenders.splice(0, pendingRenders.length);
				cacheRenders.forEach((cr) => {
					let text = '';
					if (cr.colCompute.parseNeeded) {
						text = cr.element.innerText.toLowerCase().trim();
					}

					const classList = [];
					cr.colCompute.rules.forEach((r) => {
						r.value = (r.value || '').trim();

						try {
							switch (r.comparison) {
								case PaginationTableStyleRuleComparison.Always:
									classList.push(r.class);
									break;
								case PaginationTableStyleRuleComparison.Contains:
									if (text.indexOf(r.value.toLowerCase()) !== -1) {
										classList.push(r.class);
									}
									break;
								case PaginationTableStyleRuleComparison.NotContains:
									if (text.indexOf(r.value.toLowerCase()) === -1) {
										classList.push(r.class);
									}
									break;
								case PaginationTableStyleRuleComparison.Equals:
									// eslint-disable-next-line eqeqeq
									if (text == r.value.toLowerCase()) {
										classList.push(r.class);
									}
									break;
								case PaginationTableStyleRuleComparison.NotEquals:
									// eslint-disable-next-line eqeqeq
									if (text != r.value.toLowerCase()) {
										classList.push(r.class);
									}
									break;
								case PaginationTableStyleRuleComparison.GreaterEquals:
									if (
										parseFloat(text.replace(/[^0-9.-]/g, '')) >=
										parseFloat(r.value)
									) {
										classList.push(r.class);
									}
									break;
								case PaginationTableStyleRuleComparison.LesserEquals:
									if (
										parseFloat(text.replace(/[^0-9.-]/g, '')) <=
										parseFloat(r.value)
									) {
										classList.push(r.class);
									}
									break;
								case PaginationTableStyleRuleComparison.WithinXDays: {
									const date = text
										.split('\n')
										.map((t) => spacetime(t))
										.find((t) => t.isValid());
									const days = parseInt(r.value);

									if (
										isNumber(days) &&
										date != null &&
										date.diff(new Date()).days <= days
									) {
										classList.push(r.class);
									}
									break;
								}
								case PaginationTableStyleRuleComparison.OlderXDays: {
									const date = text
										.split('\n')
										.map((t) => spacetime(t))
										.find((t) => t.isValid());
									const days = parseInt(r.value);

									if (
										isNumber(days) &&
										date != null &&
										date.diff(new Date()).days > days
									) {
										classList.push(r.class);
									}
									break;
								}
								default:
									break;
							}
						} catch (e) {
							console.error(e);
						}
					});

					cr.colCompute.rowMap.set(cr.staticItem.item, classList.join(' '));
					this.ngZone.run(() => {
						this.redrawSlow$.next(null);
					});
				});
			});
	}

	public suppressUndersizedColumnsNotification = () => {
		this.undersizedColumnsNotification = false;

		this.undersizedColumnsNotificationSuppressed = true;
		this.localStore.set(
			`${this.tableConfig.tableId}.undersizedColumnsNotificationSuppressed`,
			true,
		);

		this.redraw$.next(null);
	};

	ngOnDestroy(): void {
		this.tc_Build_RowActions_ToggleContainer_Handled.clear();

		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	/*
	 *
	 * Pagination
	 *
	 */
	public setSort = (sortKey: string, sortDescending?: boolean) => {
		if (this.sortKey === sortKey) {
			if (this.sortDescending) {
				this.sortDescending = false;
			} else {
				this.sortDescending = true;
			}
		} else {
			this.sortDescending = false;
			this.sortKey = sortKey;
		}

		if (sortDescending != null) {
			this.sortDescending = sortDescending;
		}

		this.userPrefForm.controls.sort.setValue({
			key: this.sortKey,
			descending: this.sortDescending,
		} as PaginationTableUserSortPreferences);

		this.seek(0);
	};

	public seek = (
		page: number,
		silent: boolean = false,
	): Observable<PaginationTableResultSet<T>> => {
		const resultSubject$ = new AsyncSubject<PaginationTableResultSet<T>>();
		this.seekCount++;

		if (page < 1) page = 1;
		else if (page > this.totalPages) page = this.totalPages;
		this.currentPage = Math.max(page, 1);

		this.selection_lastIndex = -1;
		this.nextSubscription$?.unsubscribe();
		this.getAllKeysSubscription$?.unsubscribe();
		this.getPageActive = true;
		this.hasFirstResult = false;

		if (!silent) {
			this.currentState$.next(TableState.loading);
		}

		this.staticItems.forEach((x) => (x.display = 'none'));

		this.redraw$.next(null);

		const getPageLocal$ = this.tableReady$.pipe(
			switchMap(() =>
				this.tableConfig.getPage$(
					this.currentPage,
					this.selectedPageSize,
					this.sortKey,
					this.sortDescending,
					this.tableConfig.search.form,
				),
			),
			tap({
				next: (x) => resultSubject$.next(x),
				error: (x) => resultSubject$.error(x),
				complete: () => resultSubject$.complete(),
			}),
		);

		this.nextSubscription$ = getPageLocal$
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: (result) => {
					this.ensureStaticItemsSet(
						this.selectedPageSize > paginationTableMaxAllowedPageSize
							? result.items.length
							: this.selectedPageSize,
					);

					this.staticItems.forEach((x) => (x.display = 'none'));
					result.items.forEach((x, i) => {
						this.staticItems[i].item = x;
						this.staticItems[i].display = null;
					});

					this.currentItems = result.items;
					this.totalCount = result.count || 0;

					this.totalPages = Math.ceil(this.totalCount / this.selectedPageSize);

					if (this.totalPages > 0 && this.totalPages < this.currentPage) {
						this.seek(this.totalPages);
					} else if (
						this.currentItems == null ||
						this.currentItems.length === 0
					) {
						this.currentState$.next(TableState.noItems);
					} else {
						this.currentState$.next(TableState.presenting);

						this.viewingLow =
							this.currentPage * this.selectedPageSize - this.selectedPageSize;

						this.viewingHigh =
							this.currentPage * this.selectedPageSize < this.totalCount
								? this.currentPage * this.selectedPageSize
								: this.totalCount;
					}

					this.redraw$.next(true);

					this.hasFirstResult = true;
				},
				error: (e) => {
					if (!(e instanceof HttpErrorResponse || e instanceof ExpectedError)) {
						console.error(e);
					}

					this.lastError = e;
					this.staticItems.forEach((x) => (x.display = 'none'));
					this.getPageActive = false;
					this.currentState$.next(TableState.error);
					this.redraw$.next(null);
				},
				complete: () => {
					this.getPageActive = false;
					this.redraw$.next(null);
				},
			});

		return resultSubject$;
	};

	public rescale = (silent?: boolean) => this.seek(0, silent);
	public refresh = (silent?: boolean) => this.seek(this.currentPage, silent);

	public selectedPageSizeChanged = () => {
		this.rescale();
		this.userPrefForm.controls.pageSize.setValue(this.selectedPageSize);
	};

	public getPaginateButtons = () => {
		const total = this.totalPages;
		const selected = this.currentPage;
		const range = isNumber(this.tableConfig.pagination.selectorRange)
			? this.tableConfig.pagination.selectorRange
			: 0;

		const totalArray = new Array(total);
		for (let i = 0; i < totalArray.length; i++) {
			totalArray[i] = i + 1;
		}

		let lowerLimit = selected - range;
		let upperLimit = selected + range;

		if (upperLimit > totalArray.length) {
			lowerLimit -= Math.abs(totalArray.length - upperLimit);
		}
		if (lowerLimit < 1) {
			upperLimit += 1 - lowerLimit;
		}

		lowerLimit = lowerLimit <= 1 ? 1 : lowerLimit;
		upperLimit =
			upperLimit >= totalArray.length ? totalArray.length : upperLimit;

		const items = new Array(0);

		for (let l = lowerLimit; l < selected; l++) {
			items.push(l);
		}
		for (let h = selected; h <= upperLimit; h++) {
			items.push(h);
		}

		return items;
	};

	private ensureStaticItemsSet = (size: number) => {
		const lengthDiff = size - this.staticItems.length;

		if (lengthDiff < 0) {
			this.staticItems.splice(
				this.staticItems.length + lengthDiff,
				Math.abs(lengthDiff),
			);
		} else {
			for (let i = 0; i < lengthDiff; i++) {
				this.staticItems.push({
					item: null,
					display: 'none',
				});
			}
		}
	};

	get htmlId(): string {
		return (
			this.tableConfig.customization.htmlIdPrefix ?? this.tableConfig.tableId
		);
	}

	/*
	 *
	 * Row Rendering
	 *
	 */
	public rows_GetRowClasses = (item: T, rowId: number): string[] => {
		if (this.tableConfig.customization.dynamicRowStyles) {
			return this.tableConfig.customization.dynamicRowStyles(item, rowId);
		} else {
			return null;
		}
	};

	/*
	 *
	 * Column Rendering
	 *
	 */
	public getColumns = (
		getAll: boolean = false,
	): PaginationTableColumnDirective<T>[] => {
		return this.columnTemplates.filter((x) => getAll || !x.disabled);
	};

	public columns_toggleAlternateDisplay = (columnId: string): void => {
		const altViewsControl =
			this.userPrefForm.controls.activeConfiguration.controls
				.alternateViewColumns;
		const newValue = altViewsControl.value;
		newValue[columnId] = !newValue[columnId];
		altViewsControl.setValue(newValue);
	};

	public columns_alternateDisplayActive = (columnId: string): boolean => {
		const altCols =
			this.userPrefForm.controls.activeConfiguration.controls
				.alternateViewColumns?.value;
		return altCols ? (altCols[columnId] ?? false) : false;
	};

	private columns_RecalculateColumns = () => {
		const activeConfig: PaginationTableSavedConfiguration = this.userPrefForm
			.controls.activeConfiguration.value as any;

		this.staticColumns.forEach(
			(x) =>
				(x.visible =
					!x.col.disabled &&
					(Boolean(
						this.userPreferences.activeConfiguration.visibleColumns[
							x.col.columnId
						],
					) ||
						x.col.locked)),
		);

		this.staticColumns.sort(
			firstBy((c) => {
				const i = (activeConfig.columnOrder || []).indexOf(c.col.columnId);
				return i !== -1 ? i : Number.MAX_SAFE_INTEGER;
			}),
		);

		const visibleColumns = this.staticColumns.filter((x) => x.visible);
		this.visibleColumnCount = visibleColumns.length;
		this.columns_visibleChanged$.next(
			visibleColumns.map((c) => c.col.columnId),
		);

		// Calculate starwidth
		this.columns_TotalStarWidth = 0;
		this.staticColumns
			.filter((x) => x.visible)
			.filter((x) => isNullOrEmptyString(x.col.fixedWidth))
			.forEach((x) => {
				this.columns_TotalStarWidth =
					this.columns_TotalStarWidth + x.col.starWidth;
			});
	};

	private columns_GetResizedWidth = (columnId: string) => {
		return this.userPrefForm.controls.activeConfiguration.controls
			.resizedColumnWidths.value[columnId];
	};

	private columns_SetResizedWidth = (columnId: string, value: number) => {
		const config =
			this.userPrefForm.controls.activeConfiguration.controls
				.resizedColumnWidths.value;
		config[columnId] = value;
		this.userPrefForm.controls.activeConfiguration.controls.resizedColumnWidths.setValue(
			config,
		);
	};

	public columns_GetWidth = (column: PaginationTableColumnDirective<T>) => {
		if (this.columns_GetResizedWidth(column.columnId) != null) {
			return `${this.columns_GetResizedWidth(column.columnId)}px`;
		} else if (isNonEmptyString(column.fixedWidth)) {
			return column.fixedWidth;
		} else {
			const value = (column.starWidth / this.columns_TotalStarWidth) * 100;
			return `${value}%`;
		}
	};

	public columns_GetColumnRowClasses = (
		staticItem: PGTableStaticItem<T>,
		col: PaginationTableColumnDirective<T>,
		element: HTMLElement,
	): string[] => {
		if (!this.columns_CalculatedStyleRules.has(col.columnId)) {
			const rules =
				this.userPrefForm.controls.activeConfiguration.controls.styleRules.controls
					.filter((r) => r.valid)
					.filter((r) => r.controls.columnId.value === col.columnId)
					.map((r) => r.value as PaginationTableStyleRule);
			this.columns_CalculatedStyleRules.set(col.columnId, {
				rules: rules,
				parseNeeded:
					rules.find(
						(r) => r.comparison !== PaginationTableStyleRuleComparison.Always,
					) != null,
				rowMap: new Map<T, string>(),
			});
		}
		const colCompute = this.columns_CalculatedStyleRules.get(col.columnId);

		if (staticItem.display == null && !colCompute.rowMap.has(staticItem.item)) {
			colCompute.rowMap.set(staticItem.item, '');

			if (colCompute.rules.length > 0) {
				this.columnStyleRenderRequest$.next({
					col,
					element,
					staticItem,
					colCompute,
				});
			}
		}

		return [
			col.displayTemplate.cellClass || '',
			colCompute.rowMap.get(staticItem.item) || '',
		];
	};

	public columns_StaticTrackBy = (
		_index: number,
		item: {
			col: PaginationTableColumnDirective<T>;
			visible: boolean;
		},
	) => {
		return item.col.columnId;
	};

	public columns_Sortable = () => {
		return this.getColumns(true).filter((c) => c.sortable);
	};

	/*
	 *
	 * Table Control - Row Actions
	 *
	 */
	public tc_RowActions_Enabled = () => {
		return (
			this.rowActionsTemplate != null &&
			!this.rowActionsTemplate.disabled &&
			this.visibleColumnCount > 0
		);
	};

	public tc_Build_RowActions_ToggleContainer = (
		tableRowElement: HTMLElement,
		show?: boolean,
	) => {
		return (event: Event) => {
			if (
				this.tc_Actions_RowActionCacheTimestamp.get(tableRowElement) ===
				event.timeStamp
			) {
				return;
			}
			this.tc_Actions_RowActionCacheTimestamp.set(
				tableRowElement,
				event.timeStamp,
			);

			const element = tableRowElement.querySelector(
				'.pgtable-row-actions-column-display .pgtable-row-actions-container',
			);

			if (element) {
				const style = getComputedStyle(
					tableRowElement.querySelector('[pgtable-actions-cell]') ??
						tableRowElement,
				).boxShadow;
				const actionsTintElement: HTMLElement = element.querySelector(
					'[name="has-actions"].pgtable-row-actions-container-tint',
				);
				const noActionsTintElement: HTMLElement = element.querySelector(
					'[name="no-actions"].pgtable-row-actions-container-tint',
				);

				const childCount = actionsTintElement?.childElementCount ?? 0;

				if (show == null) {
					element.classList.toggle('show-hover');
				} else {
					if (show) {
						element.classList.add('show-hover');
					} else {
						element.classList.remove('show-hover');
					}
				}

				if (actionsTintElement) {
					actionsTintElement.style.boxShadow = style;

					if (childCount === 0) {
						actionsTintElement.classList.add('d-none');
					} else {
						actionsTintElement.classList.remove('d-none');
					}
				}

				if (noActionsTintElement) {
					noActionsTintElement.style.boxShadow = style;

					if (childCount === 0) {
						noActionsTintElement.classList.remove('d-none');
					} else {
						noActionsTintElement.classList.add('d-none');
					}
				}
			}
		};
	};

	/*
	 *
	 * Table Control - Selection
	 *
	 */
	public tc_Selection_Enabled = () => {
		return (
			this.tableConfig.selection.keyProperty != null &&
			this.visibleColumnCount > 0
		);
	};

	public tc_Selection_Reset = (): void => {
		this.allKeysSelected = false;
		const oldCount = this.selectedItems.length;
		this.selectedItems = [];

		this.tc_Selection_NotifyChange(oldCount);
		this.redraw$.next(null);
	};

	public tc_Selection_IsItemSelected = (item: T): boolean => {
		if (this.tableConfig.selection.keyProperty == null || item === null) {
			return false;
		}

		return (
			this.selectedItems.indexOf(
				item[this.tableConfig.selection.keyProperty],
			) !== -1
		);
	};

	public tc_Selection_SelectItem = (
		item: T,
		selected: boolean,
		$event: Event,
		sendNotification: boolean = true,
	): void => {
		if (this.tableConfig.selection.keyProperty == null) {
			throw new Error(
				'TableConfig.selection.keyProperty is null. Cannot use selection',
			);
		}

		if ($event != null) {
			$event.stopPropagation();
		}

		const itemsAffected = [item];
		const oldCount = this.selectedItems.length;
		const rowIndex = this.staticItems.findIndex((si) => si.item === item);

		// Look for shift select and fill with work
		if (this.keyheld_Shift && this.selection_lastIndex > -1 && rowIndex > -1) {
			const upper = Math.max(this.selection_lastIndex, rowIndex);
			let lower = Math.min(this.selection_lastIndex, rowIndex);

			for (; lower <= upper; lower++) {
				const i = this.staticItems[lower].item;
				if (i !== item) {
					itemsAffected.push(i);
				}
			}
		}

		// Set all of the items to the correct state
		itemsAffected.forEach((i) => {
			if (
				selected &&
				this.selectedItems.indexOf(
					i[this.tableConfig.selection.keyProperty],
				) === -1
			) {
				this.selectedItems.push(i[this.tableConfig.selection.keyProperty]);
			} else if (!selected) {
				const index = this.selectedItems.indexOf(
					i[this.tableConfig.selection.keyProperty],
				);
				if (index !== -1) this.selectedItems.splice(index, 1);
			}
		});

		// Notify and complete
		if (sendNotification) {
			this.tc_Selection_NotifyChange(oldCount);
		}

		this.allKeysSelected = false;
		this.redraw$.next(null);

		// We set the index now as it was cleared in the notification
		this.selection_lastIndex = rowIndex;
	};

	public tc_Selection_IsPageSelected = (): boolean => {
		if (this.currentItems == null) return false;

		let allSelected: boolean = null;

		for (const item of this.currentItems) {
			if (this.tc_Selection_getKeyProperty(item) == null) continue;
			const selected: boolean = this.tc_Selection_IsItemSelected(item);
			if (allSelected == null) {
				allSelected = selected;
			}

			if (!selected) {
				allSelected = selected;
				break;
			}
		}
		return allSelected;
	};

	public tc_Selection_TogglePage = () => {
		if (this.currentItems == null) return;

		const oldCount = this.selectedItems.length;
		const allSelected = this.tc_Selection_IsPageSelected();

		for (const item of this.currentItems) {
			if (this.tc_Selection_getKeyProperty(item) != null) {
				this.tc_Selection_SelectItem(item, !allSelected, null, false);
			}
		}

		this.tc_Selection_NotifyChange(oldCount);
		this.redraw$.next(null);
	};

	public tc_Selection_SelectAll = (): void => {
		this.currentState$.next(TableState.loading);
		if (this.tableConfig.selection.getAllKeys$ == null) {
			throw new Error(
				'TableConfig.selection.getAllKeys$ is null. Cannot use select all.',
			);
		}

		this.getAllKeysSubscription$ = this.tableConfig.selection
			.getAllKeys$(this.tableConfig.search.form)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: (keys) => {
					const oldCount = this.selectedItems.length;
					this.selectedItems = [...new Set([...this.selectedItems, ...keys])]; // Union

					this.allKeysSelected = true;

					this.tc_Selection_NotifyChange(oldCount);
					this.currentState$.next(TableState.presenting);
					this.redraw$.next(null);
				},
				error: (e) => {
					console.error(e);
					this.lastError = e;
					this.currentState$.next(TableState.error);
				},
			});
	};

	private tc_Selection_NotifyChange = (oldCount: number) => {
		this.selection_lastIndex = -1;

		const newCount = this.selectedItems.length;
		if (oldCount !== newCount) {
			this.selection_selectionChanged$.next({
				newCount,
				oldCount,
			});
		}
	};

	public tc_Selection_getKeyProperty = (item: any): T => {
		return item[this.tableConfig.selection.keyProperty];
	};

	/*
	 *
	 * Table Control - Actions
	 *
	 */
	public tc_Actions_GetVisibleActions = () => {
		return (this.tableConfig.actions || []).filter((x) => x.visible);
	};

	public tc_Actions_ExecuteAction = (
		action: PaginationTableActionDefinition,
	) => {
		if (!this.tc_Actions_ActionDisabled(action)) {
			action.action(this.selectedItems);
		} else {
			throw new Error(
				'Tried to execute a disabled action in PaginationTableComponent',
			);
		}
	};

	public tc_Actions_ActionDisabled = (
		action: PaginationTableActionDefinition,
	) => {
		const selectedItems = this.selectedItems || [];

		let disabled = false;
		if (!disabled && action.disabled) {
			disabled = true;
		}

		if (!disabled && action.selectedNoLessThan != null) {
			disabled = selectedItems.length < action.selectedNoLessThan;
		}

		if (!disabled && action.selectedNoMoreThan != null) {
			disabled = selectedItems.length > action.selectedNoMoreThan;
		}

		return disabled;
	};

	public tc_Actions_ActionTooltip = (
		action: PaginationTableActionDefinition,
	) => {
		return this.tc_Actions_CalculatedTooltips.get(action);
	};

	public tc_Actions_CalculateTooltips = () => {
		this.tc_Actions_CalculatedTooltips.clear();

		this.tc_Actions_GetVisibleActions().forEach((x) => {
			this.tc_Actions_CalculatedTooltips.set(
				x,
				x.hoverMessage(
					this.tc_Actions_ActionDisabled(x),
					this.selectedItems.length,
				),
			);
		});
	};

	/*
	 *
	 * Table Control - Search
	 *
	 */
	public tc_Search_Enabled = (): boolean =>
		this.tableConfig.search.form != null;

	public tc_Search_HasAutoLoad = (): boolean => {
		return (
			this.userPreferences?.savedSearches?.some((ss) => ss.autoLoad) ?? false
		);
	};

	public tc_Search_Reset = () => {
		let defaults;
		if (this.tableConfig.search.defaultValue) {
			defaults = this.tableConfig.search.defaultValue();
		}

		this.tableConfig.search.form.reset(defaults);
	};

	public tc_Search_Save = () => {
		const modal = this.modalService.open(
			PaginationTableSearchSaveModalComponent,
			{
				size: 'sm',
			},
		);

		(
			modal.componentInstance as PaginationTableSearchSaveModalComponent<T>
		).bindModalData({
			pgTable: this,
		});

		modal.result
			.then((name) => {
				let savedSearchGroup =
					this.userPrefForm.controls.savedSearches.controls.find(
						(ss) => ss.controls.name.value === name,
					);

				if (savedSearchGroup == null) {
					savedSearchGroup = this.formBuild_SavedSearch_Group();
					this.userPrefForm.controls.savedSearches.push(savedSearchGroup);
				}

				const saveValue = JSON.stringify(this.tableConfig.search.form.value);
				savedSearchGroup.patchValue({
					name: name,
					value: saveValue,
				});
			})
			.catch(() => {
				// Do nothing
			});
	};

	public tc_Search_Delete = (
		search: PaginationTableSavedSearch,
		event?: Event,
	) => {
		event?.stopPropagation();
		event?.preventDefault();

		const index = this.userPrefForm.controls.savedSearches.controls.findIndex(
			(ss) => ss.controls.name.value === search.name,
		);

		if (index !== -1) {
			this.userPrefForm.controls.savedSearches.removeAt(index);
		}
	};

	public tc_Search_TogglePin = (
		search: PaginationTableSavedSearch,
		event?: Event,
	) => {
		event?.stopPropagation();
		event?.preventDefault();

		const savedSearchGroup =
			this.userPrefForm.controls.savedSearches.controls.find(
				(ss) => ss.controls.name.value === search.name,
			);

		if (savedSearchGroup != null) {
			const newValue = !savedSearchGroup.controls.autoLoad.value;

			this.userPrefForm.controls.savedSearches.controls.forEach((x) =>
				x.controls.autoLoad.setValue(false),
			);
			savedSearchGroup.controls.autoLoad.setValue(newValue);
		}
	};

	public tc_Search_Load = (search: PaginationTableSavedSearch) => {
		this.tableConfig.search.form.patchValue(JSON.parse(search.value));
	};

	public tc_Search_OpenModal = () => {
		const modalRef = this.modalService.open(
			PaginationTableSearchModalComponent,
			{
				size: 'lg',
			},
		);

		(
			modalRef.componentInstance as PaginationTableSearchModalComponent<T>
		).bindModalData({
			formGroup: this.tableConfig.search.form,
			columnTemplates: this.getColumns(),
			customFormTemplate: this.customFormTemplate.first,
		});

		modalRef.result
			.then((changedForm: FormGroup) => {
				if (this._unsubscribe$ != null) {
					copyToAbstractControl(changedForm, this.tableConfig.search.form);
				}
			})
			.catch(() => {
				// Do nothing
			});
	};

	/*
	 *
	 * Table Control - Export
	 *
	 */
	public tc_Export_OpenModal = () => {
		const modalRef = this.modalService.open(
			PaginationTableExportModalComponent,
			{
				backdrop: 'static',
				scrollable: true,
			},
		);

		(
			modalRef.componentInstance as PaginationTableExportModalComponent<T>
		).bindModalData({
			pgTable: this,
			columnTemplates: this.getColumns(),
			visibleColumns: this.userPreferences.activeConfiguration.visibleColumns,
		});

		modalRef.result
			.then(() => {
				// Do nothing
			})
			.catch(() => {
				// Do nothing
			});
	};

	/*
	 *
	 * Table Control - Refresh
	 *
	 */
	public tc_Refresh_Request = async () => {
		const stashedSeekCount = this.seekCount;
		const hardRefresh = this.tableConfig.controls.refresh.hardRefresh ?? noop;

		try {
			await hardRefresh();
		} catch (e) {
			this.toastrService.error(
				'There was an error while refreshing the data. If this issue persists contact support.',
			);
			console.error(e);
		} finally {
			if (stashedSeekCount === this.seekCount) {
				this.refresh();
			}
		}
	};

	/*
	 *
	 * Table Control - Configuration
	 *
	 */
	private tc_Configuration_GetDefaultColumns = () => {
		const columns = this.getColumns(true);
		if (columns.find((c) => c.isDefault)) {
			return columns.filter((x) => x.isDefault || x.locked);
		} else {
			return columns.filter((x) => !x.isAuxiliary || x.locked);
		}
	};

	public tc_Configuration_ToggleColumnVisibility = (columnId: string) => {
		this.userPreferences.activeConfiguration.visibleColumns[columnId] =
			!this.userPreferences.activeConfiguration.visibleColumns[columnId] ||
			(this.getColumns(true).find((x) => x.columnId === columnId)?.locked ??
				false);

		this.userPrefForm.controls.activeConfiguration.controls.visibleColumns.setValue(
			this.userPreferences.activeConfiguration.visibleColumns,
		);
	};

	public tc_Configuration_ResetColumnVisibility = () => {
		const config: PaginationTableSavedVisibleColumnConfiguration = {};
		this.tc_Configuration_GetDefaultColumns().forEach((c) => {
			config[c.columnId] = true;
		});

		this.userPrefForm.controls.activeConfiguration.controls.visibleColumns.setValue(
			config,
		);
	};

	public tc_Configuration_SelectAllColumns = () => {
		const config: PaginationTableSavedVisibleColumnConfiguration = {};
		const columns = this.getColumns(true);
		columns.forEach((x) => {
			config[x.columnId] = true;
		});

		this.userPrefForm.controls.activeConfiguration.controls.visibleColumns.setValue(
			config,
		);
	};

	public tc_Configuration_ColumnResizeStart = (
		event: MouseEvent,
		element: HTMLElement,
		column: PaginationTableColumnDirective<T>,
	) => {
		event.preventDefault();

		this.ngZone.runOutsideAngular(() => {
			const startX = event.pageX;
			const startWidth = element.clientWidth;

			const moveSubject = new Subject<MouseEvent>();
			scheduled(moveSubject, animationFrameScheduler).subscribe(
				(e: MouseEvent) => {
					this.columns_SetResizedWidth(
						column.columnId,
						startWidth + (e.pageX - startX),
					);
				},
			);

			const moveFunc = (e: MouseEvent) => {
				moveSubject.next(e);
			};

			const upFunc = () => {
				document.removeEventListener('mousemove', moveFunc);
				document.removeEventListener('mouseup', upFunc);
				moveSubject.complete();

				this.ngZone.run(() => {
					this.redraw$.next(null);
				});
			};

			document.addEventListener('mousemove', moveFunc);
			document.addEventListener('mouseup', upFunc);
		});
	};

	public tc_Configuration_RemoveColumnResize = (
		column: PaginationTableColumnDirective<T>,
	) => {
		this.columns_SetResizedWidth(column.columnId, null);
		this.redraw$.next(null);
	};

	public tc_Configuration_OpenModal = () => {
		// Run it in a new task to pull it outside of the table change detection
		this.ngZone.runTask(() => {
			const ngbModalOptions: NgbModalOptions = {};

			const ref = this.modalService.open(
				PaginationTableConfigurationModalComponent,
				ngbModalOptions,
			);
			(
				ref.componentInstance as PaginationTableConfigurationModalComponent<T>
			).bindModalData({
				pgTable: this,
			});

			this.modal_ConfigurationModalOpen$.next(true);

			ref.result.finally(() => {
				this.modal_ConfigurationModalOpen$.next(false);
			});
		});
	};

	/*
	 *
	 * Configuration Loading
	 *
	 */
	public load_SavedConfiguration = (
		config: PaginationTableSavedConfiguration,
	) => {
		this.userPrefFormIgnoreChangesCount++;
		this.userPrefForm.controls.activeConfiguration.controls.styleRules.clear();
		this.userPrefFormIgnoreChangesCount--;

		if (config.styleRules) {
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < config.styleRules.length; i++) {
				this.load_AddStyleRuleFormGroup();
			}
		}

		this.userPrefForm.controls.activeConfiguration.patchValue(config);
		this.load_ColumnVisibility(config.visibleColumns);
		this.redraw$.next(null);
	};

	private load_ColumnVisibility = (
		config: PaginationTableSavedVisibleColumnConfiguration,
	) => {
		const anyColsVisible = () => {
			const cols = this.getColumns();
			for (const i in config) {
				if (config.hasOwnProperty(i) && config[i]) {
					const col = cols.find((x) => x.columnId === i);
					if (col != null) return true;
				}
			}

			return false;
		};

		this.currentState$
			.pipe(
				filter((x) => x === TableState.presenting || x === TableState.noItems),
				take(1),
				delay(1), // We need to be on the next render cycle
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				if (config == null || !anyColsVisible()) {
					this.tc_Configuration_ResetColumnVisibility();
				}
			});
	};

	public load_AddStyleRuleFormGroup = (
		target: FormGroup<PaginationTableSavedConfigurationForm> = null,
	) => {
		if (target == null) {
			target = this.userPrefForm.controls.activeConfiguration;
		}

		const ctrl = this.formBuild_StyleRule_Group();

		this.userPrefFormIgnoreChangesCount++;
		target.controls.styleRules.push(ctrl);
		this.userPrefFormIgnoreChangesCount--;

		return ctrl;
	};

	/*
	 *
	 * Form Builders
	 *
	 */
	private formBuild_UserPreferences_Group = () => {
		return this.fb.group<PaginationTableUserPreferencesForm>({
			pageSize: new FormControl(null),
			sort: new FormControl(null),
			marginSize: new FormControl(this.tableConfig.controls.margins.default),
			smallText: new FormControl(this.tableConfig.controls.smallText.default),
			wrapText: new FormControl(this.tableConfig.controls.wrapText.default),
			sortMinimized: new FormControl(
				this.tableConfig.controls.sortMinimized.default,
			),
			stickyHeader: new FormControl(this.tableConfig.controls.sticky.default),
			expandRowActions: new FormControl(
				this.tableConfig.controls.rowActions.default,
			),
			leftRowActions: new FormControl(false),
			notifiedColumns: new FormControl([]),
			activeConfiguration: this.formBuild_SavedConfiguration_Group(),
			savedConfigurations: this.fb.array<PaginationTableSavedConfiguration>([]),
			savedSearches: this.fb.array<
				FormGroup<FormControlWrapper<PaginationTableSavedSearch>>
			>([]),
		});
	};

	public formBuild_SavedConfiguration_Control = () => {
		return this.fb.control<PaginationTableSavedConfiguration>({
			configId: null,
			name: null,
			styleRules: [],
			columnOrder: [],
			resizedColumnWidths: {},
			visibleColumns: {},
			alternateViewColumns: {},
		});
	};

	public formBuild_SavedConfiguration_Group = () => {
		return this.fb.group<PaginationTableSavedConfigurationForm>({
			configId: new FormControl(generateGuid()),
			name: new FormControl(protectedPaginationTableConfigurationDefaultName),
			styleRules: this.fb.array<
				FormGroup<FormControlWrapper<PaginationTableStyleRule>>
			>([]),
			columnOrder: new FormControl(),
			resizedColumnWidths: new FormControl({}),
			visibleColumns: new FormControl({}),
			alternateViewColumns: new FormControl({}),
		});
	};

	private formBuild_StyleRule_Group = () => {
		const ctrl = this.fb.group<FormControlWrapper<PaginationTableStyleRule>>({
			columnId: new FormControl(null, Validators.required),
			class: new FormControl('fw-bold', Validators.required),
			comparison: new FormControl(
				PaginationTableStyleRuleComparison.Always,
				Validators.required,
			),
			value: new FormControl({ value: '', disabled: true }),
		});

		ctrl.controls.comparison.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((x) => {
				if (x === PaginationTableStyleRuleComparison.Always) {
					ctrl.controls.value.disable();
					ctrl.controls.value.setValue('');
				} else {
					ctrl.controls.value.enable();
				}
			});

		return ctrl;
	};

	private formBuild_SavedSearch_Group = () => {
		return this.fb.group<FormControlWrapper<PaginationTableSavedSearch>>({
			name: new FormControl(null),
			value: new FormControl(null),
			autoLoad: new FormControl(false),
		});
	};
}
