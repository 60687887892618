import { NgClass } from '@angular/common';
import {
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validators,
} from '@angular/forms';
import {
	NgbDropdown,
	NgbDropdownButtonItem,
	NgbDropdownItem,
	NgbDropdownMenu,
	NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import spacetime from 'spacetime';
import { FormControlWrapper, TypedValidatorFn } from 'src/lib/types/forms.def';
import { SearchOperators } from 'src/lib/utilities/api/patterns/pagination/search-operators.enum';
import { isValidDate } from 'src/lib/utilities/compare';
import { convertToDate } from 'src/lib/utilities/convert';
import { isDifferentDate } from 'src/lib/utilities/date';
import { SearchOperatorIconComponent } from '../../global/search-operator-icon/search-operator-icon.component';
import {
	createDateValidation,
	createMaxCalendarDateValidation,
	createMinCalendarDateValidation,
} from '../single-date-picker/validators';

export interface EqualityComparerDateValue {
	operator: SearchOperators;
	operand: Date;
}

export const equalityComparerDateComponentValidOperators = [
	SearchOperators.Equals,
	SearchOperators.GreaterThan,
	SearchOperators.LessThan,
	SearchOperators.GreaterThanOrEqual,
	SearchOperators.LessThanOrEqual,
	SearchOperators.IsNull,
	SearchOperators.IsNotNull,
];

export const defaultSearchOperators = [
	SearchOperators.Equals,
	SearchOperators.GreaterThanOrEqual,
	SearchOperators.LessThanOrEqual,
	SearchOperators.GreaterThan,
	SearchOperators.LessThan,
	SearchOperators.IsNull,
	SearchOperators.IsNotNull,
];

export const defaultBackendSearchDefaultOperators = [
	SearchOperators.Equals,
	SearchOperators.GreaterThanOrEqual,
	SearchOperators.LessThanOrEqual,
	SearchOperators.IsNull,
	SearchOperators.IsNotNull,
];

export const defaultBackendNoEqualsDefaultOperators = [
	SearchOperators.GreaterThanOrEqual,
	SearchOperators.LessThan,
	SearchOperators.IsNull,
	SearchOperators.IsNotNull,
];

export const defaultBackendNoNullSearchDefaultOperators = [
	SearchOperators.Equals,
	SearchOperators.GreaterThanOrEqual,
	SearchOperators.LessThanOrEqual,
];

@Component({
	selector: 'ae-equality-comparer-date',
	templateUrl: './equality-comparer-date.component.html',
	styleUrls: ['./equality-comparer-date.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EqualityComparerDateComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => EqualityComparerDateComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [
		FormsModule,
		NgbDropdown,
		NgbDropdownButtonItem,
		NgbDropdownItem,
		NgbDropdownMenu,
		NgbDropdownToggle,
		NgClass,
		SearchOperatorIconComponent,
	],
})
export class EqualityComparerDateComponent
	implements ControlValueAccessor, OnChanges, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@HostBinding('class.custom-form-control') customFormControl = true;

	@Input() operators:
		| SearchOperators[]
		| 'default'
		| 'backendSearchDefault'
		| 'backendNoEqualDefault'
		| 'backendNoNullSearchDefault';

	@Input() id: string = null;

	@Input() startDate: any = null;
	@Input() minDate: any = null;
	@Input() maxDate: any = null;

	@ViewChild('dateInput') dateInput: ElementRef<HTMLElement>;

	public internalOperators: SearchOperators[] = defaultSearchOperators;

	private format: string = 'yyyy-MM-dd';

	private _minDate: Date;
	private _maxDate: Date;
	private _startDate: Date;

	private _blockAutoUpdate: boolean = false;

	public disabled: boolean = false;
	protected hideInput: boolean = false;
	public hiddenModel: any;
	public formattedModel: string;
	public ecForm: FormGroup<FormControlWrapper<EqualityComparerDateValue>>;
	protected inputType: 'date' | 'text' = 'date';

	private _touchFunction: () => void;
	private _validateFns = new Map<string, TypedValidatorFn<Date>>();
	private _changeFunction: (value: Partial<EqualityComparerDateValue>) => void =
		() => null;
	private _changeWatcher: Subscription;

	constructor(
		private ele: ElementRef<any>,
		private fb: FormBuilder,
		private ngZone: NgZone,
	) {
		this.init();
	}

	private init = () => {
		this.ecForm = this.fb.group<FormControlWrapper<EqualityComparerDateValue>>({
			operator: new FormControl(null, Validators.required),
			operand: new FormControl(null, Validators.required),
		});

		this.watchForChange();

		this.ecForm.controls.operator.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => {
				if (v === SearchOperators.IsNull || v === SearchOperators.IsNotNull) {
					this.ecForm.controls.operand.disable();
					this.hideInput = true;
				} else {
					this.ecForm.controls.operand.enable();
					this.hideInput = false;
				}
			});

		this.forceOperatorToBeValid();
		this._validateFns.set('isdate', createDateValidation());

		this.ngZone.runOutsideAngular(() => {
			document.addEventListener('keydown', this.keydownListener);
			document.addEventListener('keyup', this.keyupListener);
		});
	};

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.ecForm.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => {
				this.informChanges(v);
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	private informChanges = (value: Partial<EqualityComparerDateValue>) => {
		if (this._changeFunction) {
			if (this.ecForm.valid) {
				this._changeFunction(value);
			} else {
				this._changeFunction(null);
			}
		}

		if (this._touchFunction) {
			this._touchFunction();
		}
	};

	private forceOperatorToBeValid = () => {
		let operator = this.ecForm.controls.operator.value;
		if (
			this.internalOperators.indexOf(this.ecForm.controls.operator.value) === -1
		) {
			operator = this.internalOperators[0];
		}

		this.ecForm.controls.operator.setValue(operator);
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes.operators) {
			if (this.operators === 'default') {
				this.internalOperators = defaultSearchOperators;
			} else if (this.operators === 'backendSearchDefault') {
				this.internalOperators = defaultBackendSearchDefaultOperators;
			} else if (this.operators === 'backendNoEqualDefault') {
				this.internalOperators = defaultBackendNoEqualsDefaultOperators;
			} else if (this.operators === 'backendNoNullSearchDefault') {
				this.internalOperators = defaultBackendNoNullSearchDefaultOperators;
			} else {
				this.internalOperators = this.operators;
			}

			if (!(this.internalOperators.length > 0)) {
				throw new Error(
					'EqualityComparerDateValue.operators must be an array with values',
				);
			} else {
				this.internalOperators.forEach((x) => {
					if (equalityComparerDateComponentValidOperators.indexOf(x) === -1) {
						throw new Error(
							'EqualityComparerDateValue.operators must only contain valid operators',
						);
					}
				});
			}

			this.forceOperatorToBeValid();
		}

		if (changes.minDate) {
			this._minDate = convertToDate(changes.minDate.currentValue);

			if (this._minDate == null) {
				this._validateFns.set('minDate', null);
			} else {
				this._validateFns.set(
					'minDate',
					createMinCalendarDateValidation(this._minDate),
				);
			}
		}

		if (changes.maxDate) {
			this._maxDate = convertToDate(changes.maxDate.currentValue);

			if (this._maxDate == null) {
				this._validateFns.set('maxDate', null);
			} else {
				this._validateFns.set(
					'maxDate',
					createMaxCalendarDateValidation(this._maxDate),
				);
			}
		}

		if (changes.startDate) {
			this._startDate = convertToDate(changes.startDate.currentValue);
		}

		// Reverify the value
		if (
			(changes.minDate && !changes.minDate.firstChange) ||
			(changes.maxDate && !changes.maxDate.firstChange)
		) {
			this.informChanges(this.ecForm.value);
		}
	}

	ngOnDestroy() {
		document.removeEventListener('keydown', this.keydownListener);
		document.removeEventListener('keyup', this.keyupListener);

		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	public selectOperator = (op: SearchOperators) => {
		this.ecForm.controls.operator.setValue(op);
	};

	private updateFormattedModel = (updatedModel: Date, force = false) => {
		if (isValidDate(updatedModel)) {
			// Bug Workaround: https://github.com/spencermountain/spacetime/issues/252
			let calendarDay = spacetime(
				new Date(
					updatedModel.getFullYear(),
					updatedModel.getMonth(),
					updatedModel.getDate() + 2,
				),
			);

			if (calendarDay.isDST()) {
				calendarDay = calendarDay.subtract(2, 'day').add(1, 'hour');
			} else {
				calendarDay = calendarDay.subtract(2, 'day');
			}
			this.formattedModel = calendarDay.format(this.format);
		} else if (force) {
			this.formattedModel = null;
		}

		if (updatedModel != null) {
			this.setTouched();
		}
	};

	private setVerifiedValue = (newValue: Date) => {
		const oldValue = this.ecForm.controls.operand.value;
		let verifiedVal = convertToDate(newValue);

		if (!isValidDate(verifiedVal)) {
			verifiedVal = null;
		}

		if (oldValue?.getTime() !== verifiedVal?.getTime()) {
			this.ecForm.controls.operand.setValue(verifiedVal);
		}
	};

	public setTouched = () => {
		if (this._touchFunction) {
			this._touchFunction();
		}
	};

	public onFormattedChange = (dateModel, force = false) => {
		if (this._blockAutoUpdate && !force) {
			return;
		}
		const updatedModel = convertToDate(dateModel);

		this.updateFormattedModel(updatedModel, force);
		// update the real date given back to the parent
		this.setVerifiedValue(updatedModel);
	};

	public getMaxDate = () => {
		return this._maxDate ? spacetime(this._maxDate).format(this.format) : null;
	};

	public getMinDate = () => {
		return this._minDate ? spacetime(this._minDate).format(this.format) : null;
	};

	public getStartDate = () => {
		if (this._startDate == null) return null;
		if (this.ecForm.controls.operand.value != null) {
			return spacetime(this.ecForm.controls.operand.value).format(this.format);
		} else {
			return spacetime(this._startDate).format(this.format);
		}
	};

	public isPresentingInvalid = () => {
		return this.ele.nativeElement.classList.contains('is-invalid');
	};

	protected changeInputType = (type: 'date' | 'text') => {
		this.inputType = type;
	};

	private keydownListener = () => {
		this._blockAutoUpdate = true;
	};

	private keyupListener = () => {
		this._blockAutoUpdate = false;
	};

	// Implementing NG_VALIDATORS
	public validate = () => {
		const errors = {};

		this._validateFns.forEach((fn) => {
			if (!fn) return;

			const error = fn(this.ecForm.controls.operand);
			if (error) {
				const key = Object.keys(error)[0];
				errors[key] = error[key];
			}
		});

		if (this._validateFns.get('isdate')) {
			const isDateError = this._validateFns.get('isdate')({
				value: this.formattedModel,
			} as any);
			if (isDateError) {
				const key = Object.keys(isDateError)[0];
				errors[key] = isDateError[key];
			}
		}

		return errors;
	};

	// Implementing ControlValueAccessor
	public writeValue(val: EqualityComparerDateValue): void {
		val = val || ({} as EqualityComparerDateValue);

		try {
			this.stopWatchForChange();
			this.ecForm.controls.operator.setValue(val.operator);
			this.onFormattedChange(val.operand, true);
			this.forceOperatorToBeValid();

			if (
				this.ecForm.valid &&
				(this.ecForm.controls.operator.value !== val?.operator ||
					isDifferentDate(this.ecForm.controls.operand.value, val?.operand))
			) {
				this.informChanges(this.ecForm.value);
			}
		} catch (e) {
			throw new Error(
				`EqualityComparerDateValue.writeValue could not set value. INNER EXCEPTION: ${e}`,
			);
		} finally {
			this.watchForChange();
		}
	}
	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		this.stopWatchForChange();

		this.disabled = isDisabled;

		if (this.disabled) this.ecForm.disable();
		else this.ecForm.enable();

		this.watchForChange();
	}
}
