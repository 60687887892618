import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, Observable } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import {
	EventCategory,
	StudentEventModel,
} from 'src/lib/services/api/students/events/student-event.model';
import { StudentEventsFilter } from 'src/lib/services/api/students/events/student-events-response.model';
import { StudentsEventsService } from 'src/lib/services/api/students/events/students-events.service';
import { UserEventService } from 'src/lib/services/api/users/events/user-event.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { EqualityComparerDateValue } from 'src/lib/templates/controls/equality-comparer-date/equality-comparer-date.component';
import { PaginationTableApi } from 'src/lib/templates/tables/pagination-table/pagination-table-api';
import { PaginationTableConfig } from 'src/lib/templates/tables/pagination-table/pagination-table-config';
import { PaginationTableId } from 'src/lib/templates/tables/pagination-table/types/pagination-table-id';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { PaginationTableResultSet } from 'src/lib/types/pagination-table-result-set.def';
import { buildSearchFieldDate } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	PagingArgument,
	booleanToSortOrderDescending,
} from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { SearchOperators } from 'src/lib/utilities/api/patterns/pagination/search-operators.enum';
import { convertToInt } from 'src/lib/utilities/convert';
import { EqualityComparerDateComponent } from '../../../../templates/controls/equality-comparer-date/equality-comparer-date.component';
import { BillboardCollapsableDirective } from '../../../../templates/global/billboard-collapsable/billboard-collapsable.directive';
import { UserTimeComponent } from '../../../../templates/global/user-time/user-time.component';
import { PaginationTableColumnDisplayDirective } from '../../../../templates/tables/pagination-table/children/pagination-table-column-display.directive';
import { PaginationTableColumnHeaderDirective } from '../../../../templates/tables/pagination-table/children/pagination-table-column-header.directive';
import { PaginationTableColumnSearchDirective } from '../../../../templates/tables/pagination-table/children/pagination-table-column-search.directive';
import { PaginationTableColumnDirective } from '../../../../templates/tables/pagination-table/children/pagination-table-column.directive';
import { PaginationTableRowActionsColumnDirective } from '../../../../templates/tables/pagination-table/children/pagination-table-row-actions-column.directive';
import { PaginationTableComponent } from '../../../../templates/tables/pagination-table/core/pagination-table.component';
import { StudentEventDetailModalService } from '../../student/student-event/student-event-detail-modal/student-event-detail-modal.service';
import { StudentEventTaskCompletionModalComponent } from '../../student/student-event/student-event-task-completion-modal/student-event-task-completion-modal.component';

// Where User is creator
interface AdminEventsTableForm {
	subject: string;
	student_name: string;
	category: string;
	medium: string;
	updated: EqualityComparerDateValue;
}

// Where User is student (student event api)
interface NotificationsTableForm {
	subject: string;
	creator_name: string;
	category: string;
	medium: string;
	updated: EqualityComparerDateValue;
}

// Where user is assigned
interface TasksTableForm {
	subject: string;
	creator_name: string;
	student_name: string;
	medium: string;
	due_date: EqualityComparerDateValue;
	updated: EqualityComparerDateValue;
}

@Component({
	selector: 'ae-profile-activity',
	templateUrl: './profile-activity.component.html',
	styleUrls: ['./profile-activity.component.scss'],
	standalone: true,
	imports: [
		BillboardCollapsableDirective,
		EqualityComparerDateComponent,
		FormsModule,
		PaginationTableColumnDirective,
		PaginationTableColumnDisplayDirective,
		PaginationTableColumnHeaderDirective,
		PaginationTableColumnSearchDirective,
		PaginationTableComponent,
		PaginationTableRowActionsColumnDirective,
		ReactiveFormsModule,
		UserTimeComponent,
	],
})
export class ProfileActivityComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public userId: number;

	public notificationsTableConfig: PaginationTableConfig<
		StudentEventModel,
		FormControlWrapper<NotificationsTableForm>
	>;
	public notificationsSearchForm: FormGroup<
		FormControlWrapper<NotificationsTableForm>
	>;
	public notificationsTableApi: PaginationTableApi;

	public eventsTableConfig: PaginationTableConfig<
		StudentEventModel,
		FormControlWrapper<AdminEventsTableForm>
	>;
	public eventsSearchForm: FormGroup<FormControlWrapper<AdminEventsTableForm>>;
	public eventsTableApi: PaginationTableApi;

	public tasksTableConfig: PaginationTableConfig<
		StudentEventModel,
		FormControlWrapper<TasksTableForm>
	>;
	public tasksSearchForm: FormGroup<FormControlWrapper<TasksTableForm>>;
	public tasksTableApi: PaginationTableApi;

	public permissions = {
		canEditTaskAny: false,
		canEditTaskOwn: false,
	};

	constructor(
		private route: ActivatedRoute,
		private eventsService: UserEventService,
		private studentsEventService: StudentsEventsService,
		private fb: FormBuilder,
		private modalService: NgbModal,
		private permissionStore: PermissionStoreService,
		private studentEventDetailModalService: StudentEventDetailModalService,
	) {}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	ngOnInit() {
		this.route.params
			.pipe(
				switchMap((p) => {
					this.userId = convertToInt(p.userId);

					return this.permissionStore.getFieldSet$({
						UserId: this.userId,
					});
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((p) => {
				this.permissions.canEditTaskAny = p.canDo(
					Permits['ga_user_log|edit any user log tasks'],
				);
				this.permissions.canEditTaskOwn = p.canDo(
					Permits['ga_user_log|edit own user log tasks'],
				);

				if (!this.eventsTableConfig) {
					this.initEventsTable();
				} else {
					this.eventsTableApi?.refresh();
				}

				if (!this.tasksTableConfig) {
					this.initTasksTable();
				} else {
					this.tasksTableApi?.refresh();
				}

				if (!this.notificationsTableConfig) {
					this.initNotificationsTable();
				} else {
					this.notificationsTableApi?.refresh();
				}
			});
	}

	//
	// EVENTS
	//
	private initEventsTable = () => {
		this.eventsSearchForm = this.fb.group<
			FormControlWrapper<AdminEventsTableForm>
		>(
			{
				student_name: new FormControl(),
				category: new FormControl(),
				medium: new FormControl(),
				subject: new FormControl(),
				updated: new FormControl(),
			},
			{
				updateOn: 'change',
			},
		);

		this.eventsTableConfig = PaginationTableConfig.Create<StudentEventModel>({
			tableId: PaginationTableId['profile.activity.events'],
			getPage$: this.getEventsPage,
			search: {
				form: this.eventsSearchForm,
				debounceTime: 500,
			},
			pagination: {
				sizes: [5, 15, 30],
				initialSort: { columnId: 'updated', descending: true },
			},
		});

		this.eventsTableConfig.tableReady$
			.pipe(
				tap((x) => {
					this.eventsTableApi = x;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe();
	};

	private getEventsPage = (
		pageNumber: number,
		itemsPerPage: number,
		sortKey?: string,
		sortDescending?: boolean,
		searchForm?: FormGroup<FormControlWrapper<AdminEventsTableForm>>,
	): Observable<PaginationTableResultSet<StudentEventModel>> => {
		pageNumber = pageNumber - 1;
		const args: PagingArgument<StudentEventsFilter> = {
			skip: pageNumber * itemsPerPage,
			take: itemsPerPage,
			sort: {
				by: sortKey,
				order: booleanToSortOrderDescending(sortDescending),
			},
			filters: {
				subject: searchForm.controls.subject.value,
				student_name: searchForm.controls.student_name.value,
				medium: searchForm.controls.medium.value,
				updated: buildSearchFieldDate(
					searchForm.controls.updated.value,
					'yyyy-mm-dd',
				),
				category_exclude: [EventCategory.task, EventCategory.note],
				category: [EventCategory.registration, EventCategory.administrative],
			},
		};

		const category = searchForm.controls.category;
		if (category.value != null && category.value !== '') {
			args.filters.category = {
				value: category.value,
				operator: SearchOperators.Like,
			};
		}

		return this.eventsService.getEvents(this.userId, args).pipe(
			take(1),
			map((x) => {
				return {
					items: x.results,
					count: x.count,
				} as PaginationTableResultSet<StudentEventModel>;
			}),
		);
	};

	//
	// TASKS
	//
	private initTasksTable = () => {
		this.tasksSearchForm = this.fb.group<FormControlWrapper<TasksTableForm>>(
			{
				creator_name: new FormControl(),
				student_name: new FormControl(),
				medium: new FormControl(),
				subject: new FormControl(),
				due_date: new FormControl(),
				updated: new FormControl(),
			},
			{
				updateOn: 'change',
			},
		);

		this.tasksTableConfig = PaginationTableConfig.Create<StudentEventModel>({
			tableId: PaginationTableId['profile.activity.tasks'],
			getPage$: this.getTasksPage,
			search: {
				form: this.tasksSearchForm,
				debounceTime: 500,
			},
			pagination: {
				sizes: [5, 15, 30],
				initialSort: { columnId: 'due_date', descending: true },
			},
		});

		this.tasksTableConfig.tableReady$
			.pipe(
				tap((x) => {
					this.tasksTableApi = x;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe();
	};

	private getTasksPage = (
		pageNumber: number,
		itemsPerPage: number,
		sortKey?: string,
		sortDescending?: boolean,
		searchForm?: FormGroup<FormControlWrapper<TasksTableForm>>,
	): Observable<PaginationTableResultSet<StudentEventModel>> => {
		pageNumber = pageNumber - 1;
		const args: PagingArgument<StudentEventsFilter> = {
			skip: pageNumber * itemsPerPage,
			take: itemsPerPage,
			sort: {
				by: sortKey,
				order: booleanToSortOrderDescending(sortDescending),
			},
			filters: {
				completed_task: false,
				category: EventCategory.task,
				subject: searchForm.controls.subject.value,
				creator_name: searchForm.controls.creator_name.value,
				medium: searchForm.controls.medium.value,
				due_date: buildSearchFieldDate(
					searchForm.controls.due_date.value,
					'yyyy-mm-dd',
				),
				updated: buildSearchFieldDate(
					searchForm.controls.updated.value,
					'yyyy-mm-dd',
				),
			},
		};

		return this.eventsService.getEvents(this.userId, args).pipe(
			take(1),
			map((x) => {
				return {
					items: x.results,
					count: x.count,
				} as PaginationTableResultSet<StudentEventModel>;
			}),
		);
	};

	//
	// NOTIFICATIONS
	//
	private initNotificationsTable = () => {
		this.notificationsSearchForm = this.fb.group<
			FormControlWrapper<NotificationsTableForm>
		>(
			{
				creator_name: new FormControl(),
				category: new FormControl(),
				medium: new FormControl(),
				subject: new FormControl(),
				updated: new FormControl(),
			},
			{
				updateOn: 'change',
			},
		);

		this.notificationsTableConfig =
			PaginationTableConfig.Create<StudentEventModel>({
				tableId: PaginationTableId['profile.activity.notifications'],
				getPage$: this.getNotificationsPage,
				search: {
					form: this.notificationsSearchForm,
					debounceTime: 500,
				},
				pagination: {
					sizes: [5, 15, 30],
					initialSort: { columnId: 'updated', descending: true },
				},
			});

		this.notificationsTableConfig.tableReady$
			.pipe(
				tap((x) => {
					this.notificationsTableApi = x;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe();
	};

	private getNotificationsPage = (
		pageNumber: number,
		itemsPerPage: number,
		sortKey?: string,
		sortDescending?: boolean,
		searchForm?: FormGroup<FormControlWrapper<TasksTableForm>>,
	): Observable<PaginationTableResultSet<StudentEventModel>> => {
		pageNumber = pageNumber - 1;
		const args: PagingArgument<StudentEventsFilter> = {
			skip: pageNumber * itemsPerPage,
			take: itemsPerPage,
			sort: {
				by: sortKey,
				order: booleanToSortOrderDescending(sortDescending),
			},
			filters: {
				category_exclude: [EventCategory.task],
				category: [EventCategory.note],
				subject: searchForm.controls.subject.value,
				creator_name: searchForm.controls.creator_name.value,
				medium: searchForm.controls.medium.value,
				updated: buildSearchFieldDate(
					searchForm.controls.updated.value,
					'yyyy-mm-dd',
				),
			},
		};

		return this.studentsEventService.getStudentEvents(this.userId, args).pipe(
			take(1),
			map((x) => {
				return {
					items: x.results,
					count: x.count,
				} as PaginationTableResultSet<StudentEventModel>;
			}),
		);
	};

	//
	// TABLE ACTIONS
	//
	public openCompletedTaskModal = (selectedEvent: StudentEventModel) => {
		const ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
		};
		const classAddModal = this.modalService.open(
			StudentEventTaskCompletionModalComponent,
			ngbModalOptions,
		);

		(
			classAddModal.componentInstance as StudentEventTaskCompletionModalComponent
		).bindModalData({
			studentId: this.userId,
			item: selectedEvent,
		});

		classAddModal.result
			.then(() => {
				this.tasksTableApi.refresh();
				this.notificationsTableApi.refresh();
			})
			.catch(() => {
				// Do nothing
			});
	};

	public openEventModal = (selectedEvent: StudentEventModel) => {
		this.studentEventDetailModalService
			.openModal$(selectedEvent.student_uid, selectedEvent.id)
			.subscribe();
	};

	public isTaskEditable = (item) => {
		return (
			this.permissions.canEditTaskAny ||
			(this.permissions.canEditTaskOwn && this.userId === item.creator_uid)
		);
	};
}
